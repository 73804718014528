import "./App.css";
import React, { Fragment, PureComponent } from "react";
// Components
import Routes from "./Routes/index";
import NavBar from "./Nav/index";
import Footer from "./Footer/index";
class App extends PureComponent {
  render() {
    return (
      <Fragment>
        <header>
          <NavBar />
        </header>
        <main className="main">
          <Routes />
        </main>
        <footer>
          <Footer />
        </footer>
      </Fragment>
    );
  }
}

export default App;
