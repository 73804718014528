import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  subscribe,
  getSystemTheme,
  getSystemActiveTab,
} from "../../store/actions/app-state-action";
import moment from "moment";
import { Link } from "react-router-dom";
class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      email: "",
      success: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
      isDarkMode: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getSystemTheme();
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
    document.getElementById("collapseExample").classList.remove("show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.newsletterState !== prevProps.newsletterState) {
      if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 1
      ) {
        this.showSuccess();
      } else if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 0
      ) {
        this.showError(this.props.newsletterState.message);
      } else {
        this.showError(this.props.newsletterState.message);
      }
    }

    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleCheckBox = () => {
    this.setState({ ...this.state, checked: !this.state.checked });
  };

  handleSubscribe = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );

    const dateCreated = moment().format("YYYY-MM-DD hh:mm:ss");
    if (this.state.email.length !== 0) {
      if (!pattern.test(this.state.email)) {
        this.showError("Invalid email address inserted!");
        return false;
      }
      this.setState({ ...this.state, spinner: true });
      this.props.subscribe({ email: this.state.email, dateCreated });
    } else {
      this.showError("Email address cannot be empty!");
    }
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: this.props.newsletterState.message,
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/");
          window.top.location.href = "/";
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };

  render() {
    const { email, checked } = this.state;
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row w-100 d-flex justify-content-center mx-0">
            <div className="col-12 col-md-6 col-sm-12">
              <div
                className={
                  this.state.isDarkMode ? "dark-newsletter-card" : "card "
                }
              >
                <div className="card-body px-0">
                  <h4 className="text-center">Newsletter</h4>
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <p className="text-center">
                        Subscribe to our newsletter to get additional contents
                      </p>
                    </div>
                    <div className="col-12 mb-3">
                      <label for="email">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        aria-describedby="helpId"
                        placeholder="Email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name=""
                            id=""
                            value="checkedValue"
                            checked={checked}
                            onClick={() => this.handleCheckBox()}
                          />
                          Agree to terms and conditions
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <Link
                        to={{
                          pathname: "/termsandconditions",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <small>Read terms and conditions</small>
                      </Link>
                    </div>
                    <div className="col-12 mb-3">
                      {this.state.isError ? (
                        <div className="alert alert-danger w-100" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : this.state.isValid ? (
                        <div className="alert alert-success w-100" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn"
                              style={{
                                backgroundColor: "#ff6763",
                                color: "white",
                              }}
                              disabled={!checked}
                              onClick={() => this.handleSubscribe()}
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsletterState: state.appStore.newsletter,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  subscribe: (data) => dispatch(subscribe(data)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter);
