import React, { Fragment, PureComponent } from "react";
import "./index.css";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Clock from "react-live-clock";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import calender from "../../img/calender.svg";
import clock from "../../img/clock.svg";
import facebook from "../../img/facebook.svg";
import instagram from "../../img/instagram.svg";
import twitter from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
import NoImg from "../../img/no-img.jpg";
import moment from "moment";
import logo from "../../img/logo.png";

import {
  getAllPosts,
  getPostBySearch,
  getSystemTheme,
  getSystemActiveTab,
  getAllAds,
} from "../../store/actions/app-state-action";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#e9ecef",
        borderColor: "#e9ecef",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
class NavBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      posts: [],
      success: false,
      search: [],
      showSearch: false,
      isLoading: false,
      keyword: "",
      active: {
        0: true,
        1: false,
        2: false,
        3: false,
      },
      errorMsg: "",
      isError: false,
    };
  }

  componentDidMount() {
    var language = window.localStorage.getItem("Lan");

    if (language == undefined) {
      localStorage.setItem("Lan", "Sinhala");
    }

    this.props.getAllAds();
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        posts: this.props.postsState.data,
        success: true,
      });
    } else {
      this.props.getAllPosts(0, 12);
    }
    var element = document.getElementsByTagName("body")[0];
    const themeType = localStorage.getItem("systemTheme");
    if (themeType === "darkTheme") {
      localStorage.setItem("systemTheme", "lightTheme");
      this.setState({
        ...this.state,
        checked: false,
      });
      this.props.getSystemTheme();
      element.classList.remove("darkTheme");
      element.classList.add("lightTheme");
    }
    // else {
    //   localStorage.setItem("systemTheme", "darkTheme");
    //   this.setState({
    //     ...this.state,
    //     checked: true,
    //   });
    //   this.props.getSystemTheme();
    //   element.classList.remove("lightTheme");
    //   element.classList.add("darkTheme");
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.postsState !== this.props.postsState) {
      if (this.props.postsState.success) {
        this.setState({
          ...this.state,
          posts: this.props.postsState.data,
          success: true,
        });
      }
    }

    if (this.props.searchState !== prevProps.searchState) {
      if (this.props.searchState.success) {
        this.setState({
          ...this.state,
          search: this.props.searchState.data,
          success: true,
          isLoading: false,
        });
      }
    }

    if (this.props.systemActiveTabState !== prevProps.systemActiveTabState) {
      if (parseInt(this.props.systemActiveTabState.data) === 0) {
        this.setState({
          ...this.state,
          active: {
            ...this.state.active,
            0: true,
            1: false,
            2: false,
            3: false,
          },
        });
      } else if (parseInt(this.props.systemActiveTabState.data) === 1) {
        this.setState({
          ...this.state,
          active: {
            ...this.state.active,
            0: false,
            1: true,
            2: false,
            3: false,
          },
        });
      } else if (parseInt(this.props.systemActiveTabState.data) === 3) {
        this.setState({
          ...this.state,
          active: {
            ...this.state.active,
            0: false,
            1: false,
            2: false,
            3: true,
          },
        });
      }
    }
  }

  handleChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ ...this.state, [name]: checked });

    localStorage.setItem(
      "systemTheme",
      this.state.checked ? "lightTheme" : "darkTheme"
    );
    this.props.getSystemTheme();
    var element = document.getElementsByTagName("body")[0];
    if (element.classList.contains("lightTheme")) {
      element.classList.remove("lightTheme");
      element.classList.add("darkTheme");
    } else {
      element.classList.remove("darkTheme");
      element.classList.add("lightTheme");
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ ...this.state, keyword: value });
  };

  handleClick = () => {
    if (this.state.keyword.length !== 0) {
      this.props.getPostBySearch(this.state.keyword);
      this.setState({ ...this.state, showSearch: true, isLoading: true });
    } else {
      this.showError("Please enter your search topic!");
    }
  };

  handleRedirect = (name) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + name);
    window.top.location.href = `/focus-article.php?slug=` + name;
  };

  handleACtiveTab = (prev, next) => {
    this.setState({
      ...this.state,
      active: {
        ...this.state.active,
        [prev]: false,
        [next]: true,
      },
    });
    if (next === 0) {
      localStorage.setItem("SAT", 0);
      this.props.getSystemActiveTab();
      // this.props.history.push("/");
      window.top.location.href = "/";
      document.getElementById("collapseExample").classList.remove("show");
    } else {
      localStorage.setItem("SAT", 1);
      this.props.getSystemActiveTab();
      // this.props.history.push("/citizen/focus");
      window.top.location.href = "/citizen/focus";
      document.getElementById("collapseExample").classList.remove("show");
    }
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        showSearch: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3000);
      }
    );
  };

  openNav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar-bd").style.width = "100%";
    document.getElementById("mySidebar-bd").style.visibility = "initial";
    document.getElementById("mySidebar").style.visibility = "initial";
    document.getElementsByClassName(
      "side-navbar-info-container"
    )[0].style.visibility = "initial";
  };
  closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("mySidebar-bd").style.width = "0";
    document.getElementById("mySidebar-bd").style.visibility = "hidden";
    document.getElementById("mySidebar").style.visibility = "hidden";
    document.getElementsByClassName(
      "side-navbar-info-container"
    )[0].style.visibility = "hidden";
  };
  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  handleLangChange = (item) => {
    if (item) {
      if (item == "English") {
        localStorage.setItem("Lan", item);
        window.top.location.href = "https://citizen.lk/";
      }
      if (item == "Sinhala") {
        localStorage.setItem("Lan", item);
        window.top.location.href = "https://si.citizen.lk/";
      }
      if (item == "Tamil") {
        localStorage.setItem("Lan", item);
        window.top.location.href = "https://ta.citizen.lk/";
      }
    }
  };

  handleMove = () => {
    window.top.location.href = "/";
  };

  render() {
    var today = new Date();
    return (
      <Fragment>
        <div className="header-01">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div
                    className="row d-flex jalign-items-center "
                    style={{ alignContent: "center" }}
                  >
                    <a
                      href={() => {
                        this.handleMove();
                      }}
                    >
                      <label className="icon-spacing-02 nav-header-text-01 r">
                        Learn Fact-Checking
                      </label>
                    </a>
                    <a
                      href={() => {
                        this.handleMove();
                      }}
                    >
                      <label className="icon-spacing-02 nav-header-text-01 ">
                        Support Our Work
                      </label>
                    </a>

                    {/* <img className="left-icons" src={calender} alt="calender" />
                    <p className="nav-header-text-01 icon-spacing-01 icon-spacing-02">
                      {moment(today).local("en").format("DD MMMM, YYYY")}
                    </p>
                    <img className="left-icons" src={clock} alt="clock" />
                    <p className=" nav-header-text-01 icon-spacing-01">
                      <Clock format={"hh:mm A"} ticking={true} />
                    </p> */}
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end ">
                  <div className="row mb-2">
                    {/* <div className="dropdown icon-spacing-02">
                      <p
                        className="dropdown-toggle header-text-01"
                        id="triggerId"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        தமிழ்
                      </p>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="triggerId"
                      >
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                        <a className="dropdown-item disabled" href="#">
                          Disabled action
                        </a>
                        <h6 className="dropdown-header">Section header</h6>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">
                          After divider action
                        </a>
                      </div>
                    </div> */}
                    {/* <a href={"https://www.citizen.lk/"}> */}
                    <button
                      type="button"
                      id="nav-header-text-01"
                      className="btn icon-spacing-02 nav-header-text-01"
                      onClick={(e) => this.handleLangChange("Sinhala")}
                    >
                      සිංහල
                    </button>
                    {/* </a> */}
                    {/* <a href={"https://www.citizen.lk/ta/"}> */}
                    <button
                      type="button"
                      id="nav-header-text-01"
                      className="btn icon-spacing-02 nav-header-text-01"
                      onClick={(e) => this.handleLangChange("Tamil")}
                    >
                      தமிழ்
                    </button>
                    {/* </a> */}
                    {/* <a href={"https://www.citizen.lk/en/"}> */}
                    <button
                      type="button"
                      id="nav-header-text-01"
                      className="btn icon-spacing-02 nav-header-text-01"
                      onClick={(e) => this.handleLangChange("English")}
                    >
                      English
                    </button>
                    {/* </a> */}
                    <i
                      class="fa fa-bars fa-1x align-items-center"
                      id="hamburger-navbar-icon"
                      aria-hidden="true"
                      style={{
                        color: " white",
                        cursor: "pointer",
                        marginTop: "0.053vw",
                      }}
                      onClick={(e) => this.openNav()}
                    ></i>

                    <a
                      className="my-auto"
                      href="https://www.facebook.com/citizenlk/"
                    >
                      <img
                        className="icon-circle-01"
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://www.instagram.com/citizen.lk/"
                    >
                      <img
                        className="icon-circle-02"
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://twitter.com/citizen_newslk"
                    >
                      <img
                        className="icon-circle-02"
                        src={twitter}
                        alt="twitter"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                    >
                      <img
                        className="icon-circle-02"
                        src={youtube}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- side navbar --> */}
        <div className="side-navbar-wrapper" id="mySidebar">
          <div className="back-drop" id="mySidebar-bd">
            <div className="row">
              <div
                className="col-12 col-sm-12 side-navbar-info-container"
                style={{ backgroundColor: "black" }}
              >
                <div className="row">
                  <div className="col-6 col-sm-9 col-md-8 col-lg-7 col-xl-6">
                    <div className="row">
                      <a href="/" style={{ textDecoration: "none" }}>
                        <img
                          className="header-logo-2 my-auto"
                          src={logo}
                          alt="WorldReady"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-3 col-md-4 col-lg-5 col-xl-6">
                    <div className="row d-flex justify-content-end h-100 align-items-center">
                      <div className="col-1 d-flex justify-content-end my-auto p-0 ml-2 ml-sm-0">
                        <i
                          className="fa fa-times fa-2x"
                          aria-hidden="true"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            marginRight: "29px",
                          }}
                          onClick={(e) => this.closeNav()}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-sm-12 col-md-12 text-start">
                    <div className="side-navbar-links">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://si.citizen.lk/"}>
                            සිංහල
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://ta.citizen.lk/"}>
                            தமிழ்
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://citizen.lk/"}>
                            ENGLISH
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 m-auto text-start ">
                    <div className="side-navbar-links">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://fc.citizen.lk/"}>
                            FACT CHECK
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a
                            className="ml-3"
                            href={"https://www.helakuru.lk/esana"}
                          >
                            එසැණ
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://yournews.lk/"}>
                            CITIZEN NEWS
                          </a>
                        </div>
                        <div className="col-12"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 m-auto text-start ">
                    <div className="side-navbar-links">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <a className="ml-3">SUBMIT A CLAIM TO FACT-CHECK</a>
                        </div>
                        <div className="col-12 mt-2">
                          <a
                            className="ml-3"
                            onClick={() =>
                              // this.props.history.push("/newsletter")
                              (window.top.location.href = "/newsletter")
                            }
                          >
                            NEWSLETTER
                          </a>
                        </div>

                        <div className="col-12"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 mt-2">
                    <ul id="category-tabs">
                      <li>
                        <a
                          href="#"
                          className="main-category"
                          data-toggle="collapse"
                          data-target="#collapseExample0"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          FACT-CHECKS
                          <i
                            className="fa fa-minus"
                            data-toggle="collapse"
                            data-target="#collapseExample0"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          ></i>
                        </a>
                        <div className="collapse" id="collapseExample0">
                          <ul className="sub-category-tabs">
                            <li>
                              <a href="#">Viral</a>
                            </li>
                            <li>
                              <a href="#">Political</a>
                            </li>
                            <li>
                              <a href="#">Health</a>
                            </li>
                            <li>
                              <a href="#">Economy</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <ul id="category-tabs">
                      <li>
                        <a
                          href="#"
                          className="main-category"
                          data-toggle="collapse"
                          data-target="#collapseExample1"
                          aria-expanded="false"
                          aria-controls="collapseExample1"
                        >
                          WHO WE ARE
                          <i
                            className="fa fa-minus"
                            data-toggle="collapse"
                            data-target="#collapseExample1"
                            aria-expanded="false"
                            aria-controls="collapseExample1"
                          ></i>
                        </a>
                        <div className="collapse" id="collapseExample1">
                          <ul className="sub-category-tabs">
                            <li>
                              <a href="/about-us">About Us</a>
                            </li>
                            <li>
                              <a href="#">Trustees & Patrons</a>
                            </li>
                            <li>
                              <a href="our-team">Our Team</a>
                            </li>
                            <li>
                              <a href="/non-partisanship-policy">
                                Non-Partisanship Policy
                              </a>
                            </li>
                            <li>
                              <a href="/contact-us">Contact Us</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <ul id="category-tabs">
                      <li>
                        <a
                          href="#"
                          className="main-category"
                          data-toggle="collapse"
                          data-target="#collapseExample2"
                          aria-expanded="false"
                          aria-controls="collapseExample2"
                        >
                          WHAT WE DO
                          <i
                            className="fa fa-minus"
                            data-toggle="collapse"
                            data-target="#collapseExample2"
                            aria-expanded="false"
                            aria-controls="collapseExample2"
                          ></i>
                        </a>
                        <div className="collapse" id="collapseExample2">
                          <ul className="sub-category-tabs">
                            <li>
                              <a href="#">LIVE Fact Check</a>
                            </li>
                            <li>
                              <a href="#">Explainers</a>
                            </li>
                            <li>
                              <a href="#">Infographics</a>
                            </li>
                            <li>
                              <a href="#">Fact-checking Research</a>
                            </li>
                            <li>
                              <a href="#">Workshops & Courses</a>
                            </li>
                            <li>
                              <a href="#">Media Literacy</a>
                            </li>
                            <li>
                              <a href="#">Collaborations</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <ul id="category-tabs">
                      <li>
                        <a
                          href="#"
                          className="main-category"
                          data-toggle="collapse"
                          data-target="#collapseExample3"
                          aria-expanded="false"
                          aria-controls="collapseExample3"
                        >
                          HOW WE FACT-CHECK
                          <i
                            className="fa fa-minus"
                            data-toggle="collapse"
                            data-target="#collapseExample3"
                            aria-expanded="false"
                            aria-controls="collapseExample3"
                          ></i>
                        </a>
                        <div className="collapse" id="collapseExample3">
                          <ul className="sub-category-tabs">
                            <li>
                              <a href="/methodology">Methodology</a>
                            </li>
                            <li>
                              <a href="/corrections-and-complaints-policy">
                                Corrections And Complaints Policy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row ml-1 navbar-bottom-row d-flex align-items-end">
                  <div
                    className="col-md-12 d-flex flex-row my-0 p-0"
                    style={{ height: "fit-content" }}
                  >
                    <div className="navbar-social-col d-flex justify-content-center ">
                      <a
                        className="my-auto"
                        href="https://www.facebook.com/citizenlk/"
                      >
                        <img
                          // className="icon-circle-01"
                          src={facebook}
                          alt="facebook"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://www.instagram.com/citizen.lk/"
                      >
                        <img
                          src={instagram}
                          alt="instagram"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://twitter.com/citizen_newslk"
                      >
                        <img
                          // className="icon-circle-02"
                          src={twitter}
                          alt="twitter"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                      >
                        <img
                          // className="icon-circle-02"
                          src={youtube}
                          alt="youtube"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 ml-2 my-3 p-0">
                    <p className="navbar-copyright-text mb-0">
                      © Citizen.lk All rights reserved. Digital Content Private
                      Limited, No 96/8, Kottanuwatta Road, Nugegoda, Sri Lanka.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-wrapper-02">
          <div className="row d-none d-sm-flex mx-0 align-items-center">
            <div className="col-1"></div>
            <div className="col-2 my-auto">
              {/* <a href="#" style={{ color: "black", textDecoration: "none" }}>
                <p
                  className={
                    !this.state.checked
                      ? "nav-text-dark text-center"
                      : "nav-text-lightTheme text-center"
                  }
                >
                  Academy
                </p>
              </a> */}
            </div>
            <div className="col-2 my-auto">
              {/* <div className="row d-flex justify-content-start">
               
                <a
                  href="https://citizen.lk/fc/"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <p
                    className={
                      !this.state.checked
                        ? "nav-text-dark "
                        : "nav-text-lightTheme"
                    }
                  >
                    Fact Check
                  </p>
                </a>
              </div> */}
            </div>
            <div className="col-2">
              <a
                href="https://fc.citizen.lk/"
                style={{ color: "black", textDecoration: "none" }}
              >
                <img
                  className="header-logo"
                  src={logo}
                  alt="logo"
                  onClick={(e) =>
                    // this.props.history.push("/")
                    (window.top.location.href = "/")
                  }
                />
              </a>
            </div>
            <div className="col-2 my-auto">
              {/* <a
                href="https://www.helakuru.lk/esana"
                style={{ color: "black", textDecoration: "none" }}
              >
                <p
                  className={
                    !this.state.checked
                      ? "nav-text-dark-02"
                      : "nav-text-lightTheme-02"
                  }
                >
                  එසැණ
                </p>
              </a> */}
            </div>
            <div className="col-2 my-auto">
              {/* <a
                href="https://yournews.lk/"
                style={{ color: "black", textDecoration: "none" }}
              >
                <p
                  className={
                    !this.state.checked
                      ? "nav-text-dark text-left"
                      : "nav-text-lightTheme text-left"
                  }
                >
                  Citizen News
                </p>
              </a> */}
            </div>
            <div className="col-1 d-flex justify-content-end">
              <a
                className="text-dark my-auto"
                data-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i
                  className={
                    !this.state.checked
                      ? "fa fa-search icon-search-dark_theme"
                      : " fa fa-search icon-search-light_theme"
                  }
                  aria-hidden="true"
                ></i>
              </a>
              {/* <i className="fa fa-align-right my-auto" aria-hidden="true"></i> */}
            </div>
          </div>
        </div>
        <div className="header-02">
          <div className="row">
            <div className="col-6 col-sm-3 col-md-3 col-lg-2 pl-0 pr-0 pr-sm-3 order-12 order-sm-1 mb-3 mb-sm-0 d-flex justify-content-center justify-content-sm-end">
              {/* <div className="header-container d-flex justify-content-center justify-content-sm-start p-3">
                <WbSunnyOutlinedIcon className="mr-2 my-auto" />
                <AntSwitch
                  className="mr-2 my-auto"
                  checked={this.state.checked}
                  onChange={this.handleChange}
                  name="checked"
                />
                <i className="fa fa-moon-o my-auto" aria-hidden="true"></i>
              </div> */}
            </div>
            <div className="col-12 col-sm-7 d-flex justify-content-center order-12 order-sm-2 mb-3 mb-sm-0 .d-none d-sm-none d-md-none d-lg-block">
              {/* <p
                className={
                  this.state.active[0]
                    ? "header-text-01 active"
                    : "header-text-01"
                }
                onClick={(e) => this.handleACtiveTab(1, 0)}
              >
                FACT CHECK
              </p>
              <p
                className={
                  this.state.active[1]
                    ? "header-text-01 active"
                    : "header-text-01"
                }
                onClick={(e) => this.handleACtiveTab(0, 1)}
              >
                CITIZEN FOCUS
              </p>
              <p
                className={
                  this.state.active[2]
                    ? "header-text-01 active"
                    : "header-text-01"
                }
              >
                <a
                  href="https://www.citizen.lk/"
                  className={
                    !this.state.checked
                      ? "a_tag_text_dark_theme"
                      : "a_tag_text_light_theme"
                  }
                >
                  CITIZEN පුවත්
                </a>
              </p> */}

              <ul class="navbar-links d-flex justify-content-center">
                <li class="navbar-dropdown">
                  <a href="#">FACT-CHECKS</a>
                  <div class="dropdown">
                    <a href="#">Viral</a>
                    <a href="#">Political</a>
                    <a href="#">Health</a>
                    <a href="#">Economy</a>
                  </div>
                </li>
                <li class="navbar-dropdown">
                  <a href="#">WHO WE ARE</a>
                  <div class="dropdown">
                    <a href="/about-us">About Us</a>
                    <a href="/trustees-patrons">Trustees & Patrons</a>
                    <a href="/our-team">Our Team</a>
                    <a href="/non-partisanship-policy">
                      Non-Partisanship Policy
                    </a>
                    <a href="/contact-us">Contact Us</a>
                  </div>
                </li>
                <li class="navbar-dropdown">
                  <a href="#">WHAT WE DO</a>
                  <div class="dropdown">
                    <a href="#">LIVE Fact Check</a>
                    <a href="#">Explainers </a>
                    <a href="#">Infographics</a>
                    <a href="#">Fact-checking Research</a>
                    <a href="#">Workshops & Courses</a>
                    <a href="#">Media Literacy</a>
                    <a href="#">Collaborations </a>
                  </div>
                </li>
                <li class="navbar-dropdown">
                  <a href="#">HOW WE FACT-CHECK</a>
                  <div class="dropdown">
                    <a href="/methodology">Methodology</a>
                    <a href="/corrections-and-complaints-policy">
                      Corrections And Complaints Policy
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-6 col-sm-7 col-md-7 col-lg-3 my-auto d-flex justify-content-center justify-content-sm-end pl-0  pr-0 order-1 order-sm-11">
              <img
                id="navbar-sub-menu-logo"
                className="header-navbar-sub-menu-logo align-self-center"
                src={logo}
                alt="logo"
                onClick={(e) =>
                  //  this.props.history.push("/")
                  (window.top.location.href = "/")
                }
              />
              <i
                class="fal fa-exclamation-circle align-self-center"
                id="navbar-sub-menu"
              ></i>
              <p
                id="navbar-sub-menu"
                className={
                  this.state.active[3]
                    ? "header-text-01 active text-center"
                    : "header-text-01 text-center"
                }
              >
                SUBMIT A CLAIM TO FACT-CHECK
              </p>
              <i
                id="navbar-sub-menu"
                className="fas fa-envelope  align-self-center "
                aria-hidden="true"
                style={{
                  color: `${!this.state.checked ? "black" : "white"}`,
                }}
              ></i>
              <p
                id="navbar-sub-menu"
                className={
                  this.state.active[3]
                    ? "header-text-01 active"
                    : "header-text-01"
                }
                onClick={() =>
                  //  this.props.history.push("/newsletter")
                  (window.top.location.href = "/newsletter")
                }
              >
                NEWSLETTER
              </p>
            </div>
          </div>
        </div>

        <div className="header-02-sm">
          <div className="row mx-0 p-0">
            <div class="col-md-12 d-flex justify-content-center">
              <img
                id="navbar-sub-menu-logo-sm"
                className="header-navbar-sub-menu-logo align-self-center"
                src={logo}
                alt="logo"
                onClick={(e) =>
                  // this.props.history.push("/")
                  (window.top.location.href = "/")
                }
              />
            </div>
          </div>
        </div>
        <div
          className="collapse"
          id="collapseExample"
          style={{ position: "absolute", width: "100%" }}
        >
          <div className="card search-card">
            <div className="row mx-0">
              <div className="col-12 col-sm-10 mb-3 mb-sm-0">
                <TextField
                  id="input-with-icon-textfield"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ cursor: "pointer", marginRight: "15px" }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <CloseIcon
                          data-toggle="collapse"
                          href="#collapseExample"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              keyword: "",
                              showSearch: false,
                            })
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.keyword}
                  onChange={this.handleSearchChange}
                  placeholder="Enter your search topic"
                />
              </div>
              <div className="col-12 col-sm-2">
                <button
                  type="button"
                  className="btn text-white btn-block"
                  style={{ backgroundColor: "#cd3235" }}
                  onClick={(e) => this.handleClick()}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="row mx-0">
              {!this.state.showSearch ? (
                <Fragment>
                  <div className="col-12">
                    <div className="row mt-4 mx-0">
                      <div className="col-12">
                        <p className="search-heading">THE LATEST</p>
                      </div>
                    </div>
                  </div>
                  {this.state.success ? (
                    this.state.posts
                      .map((item, index) => {
                        return (
                          <div className="col-12 col-sm-3" key={index}>
                            <a
                              href={`/citizen/focus/` + item.Slug}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <div className="row">
                                <div className="col-4">
                                  <div className="search-news-circle card">
                                    <img
                                      className="search-news-circle"
                                      src={
                                        item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                          ? API_STORAGE_BASE_URL +
                                            item.Thumbnail
                                          : NoImg
                                      }
                                      onError={this.addDefaultSrc}
                                      alt="latest news"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-8"
                                  style={{ borderRight: "1px solid #d5d7d8" }}
                                >
                                  <p className="search-news-title">
                                    {item.Title}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                      .slice(0, 4)
                  ) : (
                    <Fragment>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <div className="search-list-container mt-2">
                  {this.state.isError ? (
                    <div className="alert alert-danger mt-3" role="alert">
                      {this.state.errorMsg}
                    </div>
                  ) : !this.state.isLoading ? (
                    this.state.search.length !== 0 ? (
                      this.state.search.map((item, index) => {
                        return (
                          <a
                            href={`/citizen/focus/` + item.Slug}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <div
                              className="row mt-2"
                              data-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <div className="col-12 mt-3" key={index}>
                                <div className="row mx-0">
                                  <div className="col-3 col-sm-2">
                                    <div className="news-img-container">
                                      <img
                                        className="trending-img"
                                        src={
                                          item.Thumbnail.length !== 0 &&
                                          item.Thumbnail
                                            ? API_STORAGE_BASE_URL +
                                              item.Thumbnail
                                            : NoImg
                                        }
                                        onError={this.addDefaultSrc}
                                        alt="news"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-9 col-sm-10">
                                    <p className="trending-news-text">
                                      {item.Title}
                                    </p>
                                    <p>
                                      <p className="trending-news-subtext my-auto">
                                        {moment(item.DateCreated)
                                          .local("si")
                                          .startOf("seconds")
                                          .fromNow()}
                                      </p>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    ) : this.state.search.length !== 0 ? null : (
                      <p
                        className={
                          this.state.checked
                            ? "text-dark text-center"
                            : "text-center"
                        }
                      >
                        Search results not found!
                      </p>
                    )
                  ) : (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchState: state.appStore.search,
    postsState: state.appStore.posts,
    systemActiveTabState: state.appStore.systemActiveTab,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostBySearch: (data) => dispatch(getPostBySearch(data)),
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
  getAllAds: () => dispatch(getAllAds()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
