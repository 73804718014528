import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
import CMN from "../../../img/CMN-footer.webp";
class TrusteesPatrons extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Trustees & Patrons</h1>
          <h6 className="mt-5 my-3 font-weight-bold">TRUSTEE</h6>
          <p>
            Citizen Media Network (CMN) is the sole trustee of Citizen Fact
            Check. The CMN consisting of new media outlets Esana, Your News,
            Citizen Wrap, Citizen News Agency and Citizen Fact Check was founded
            and is managed by its directors Chamila Prasad Karunarathne, Arjun
            Jeger .
          </p>
          <p>
            CMN is headed by its Editor, Arunasiri Udayakanta, an experienced
            digital media journalist. He previously worked as an associate
            editor of Esana News Service which is a sister company of CMN.
          </p>
          <p>Email: arunasiri@citizen.lk</p>
          <div className="row mx-0">
            <div className="col-md-12 d-flex justify-content-center">
              <img src={CMN} className="cmn-img" />
            </div>
            <div className="col-md-12">
              <p className="mt-3 text-center">
                The Citizen Media Network consisting of new media outlets Esana,
                Your News, Citizen Wrap, Citizen Fact Check & Citizen News
                Agency
              </p>
            </div>
          </div>
          <h6 className="mt-5 my-3 font-weight-bold">PATRON</h6>
          <p>
            Digital Content (Private) Limited (DC) is a fully Integrated
            Creative, Digital Content, Video Production & Digital Marketing
            Communications Agency. DC was co-founded by Chamila Prasad
            Karunarathne and Arjun Jeger in 2017. The duo have over 15 years of
            experience in the field of digital media.
          </p>
          <p>
            Visit www.digitalcontent.lk for more information on Digital Content
            (Private) Limited.
          </p>

          <p>Email : arjun@digitalcontent.lk / chamila@digitalcontent.lk</p>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(TrusteesPatrons);
