import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
import sandun from "../../../img/Sandun-Arosha-Citizen-Fact-Check-1.webp";
import maneshka from "../../../img/Maneshka-Citizen-Fact-Check.webp";
import sahan from "../../../img/Sahan-Citizen-Fact-Check.webp";
import mifla from "../../../img/Mifla-Bulkis-Citizen-Fact-Check.webp";
class OurTeam extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Our Team</h1>
          <h6 className="mt-5 my-3 font-weight-bold">Alumni</h6>
          <div className="row">
            <div className="col-md-4">
              <img src={sandun} style={{ width: "392px" }} />
            </div>
            <div className="col-md-8">
              <p className="mt-5">
                <b>Sandun Arosha Fernando</b>
              </p>
              <p>
                <b>Co – Founder and Editor in Chief</b>
              </p>
              <p>
                <b>(2021 -01 -01 to 2022 – 08- 08) Citizen Fact Check</b>
              </p>

              <div class="social-share-btns">
                <a
                  class="share-btn share-btn-twitter"
                  href="https://twitter.com/sandunarosha"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334c0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518a3.301 3.301 0 0 0 1.447-1.817a6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429a3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218a3.203 3.203 0 0 1-.865.115a3.23 3.23 0 0 1-.614-.057a3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                    />
                  </svg>
                  &nbsp;Tweet
                </a>
                <a
                  class="share-btn share-btn-facebook"
                  href="https://www.facebook.com/SandunAroshaFernando"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131c.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                <a
                  class="share-btn share-btn-linkedin"
                  href="https://www.linkedin.com/in/sandun-arosha-96283961/"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                {/* <a
                  class="share-btn share-btn-reddit"
                  href="http://www.reddit.com/submit?url=https://codepen.io/marko-zub/&amp;title=Marko+Zub+codepen"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306a.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487c-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0a.213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83c.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z" />
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325c-.801-.573-1.9-.945-3.121-.993l.534-2.501l1.738.372a.83.83 0 1 0 .83-.869a.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028a.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992c-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353c0 1.795 2.091 3.256 4.669 3.256c2.577 0 4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353c.401-.181.688-.592.688-1.069c0-.65-.525-1.165-1.165-1.165z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a> */}
                <a
                  class="share-btn share-btn-mail"
                  href="mailto:arosha@digitalcontent.lk"
                  rel="nofollow"
                  target="_blank"
                  title="via email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178l1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494l-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363L1.591 6.602l11.833-4.733Z" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a>
              </div>
            </div>
          </div>

          <p className="mt-3">
            Sandun Arosha Fernado is an award winning journalist with over a
            decade’s experience in the field of journalism. In 2014 Fernando
            received the prestigious award for the Best News Reporter of the
            Year at the Raigam Tele awards.
          </p>
          <p className="mt-2">
            Beginning his career in broadcast journalism, as a presenter and
            reporter for several leading radio and television channels in Sri
            Lanka, today he is the Editor-in-Chief of the Citizen Media Network
            which consists of several media outlets, including the popular
            Citizen.lk and Esana breaking news service.
          </p>
          <p className="mt-2">
            Through his experiences and knowledge gathered through in-depth
            training in fact checking Fernando envisioned commencing an
            international standard fact checking service in Sri Lanka. Citizen
            Fact Check, the first of its kind by a media organisation in Sri
            Lanka is his brain child.
          </p>

          <div className="row">
            <div className="col-md-4">
              <img src={maneshka} style={{ width: "392px" }} />
            </div>
            <div className="col-md-8">
              <p className="mt-5">
                <b>Maneshka Borham</b>
              </p>
              <p>
                <b>
                  Associate Editor (2021 -04 -01 to 2022 – 07- 10) Citizen Fact
                  Check
                </b>
              </p>
              <div class="social-share-btns">
                <a
                  class="share-btn share-btn-twitter"
                  href="https://twitter.com/mann_ra"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334c0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518a3.301 3.301 0 0 0 1.447-1.817a6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429a3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218a3.203 3.203 0 0 1-.865.115a3.23 3.23 0 0 1-.614-.057a3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                    />
                  </svg>
                  &nbsp;Tweet
                </a>
                <a
                  class="share-btn share-btn-facebook"
                  href="https://www.facebook.com/maneshkaborham"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131c.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                <a
                  class="share-btn share-btn-linkedin"
                  href="https://www.linkedin.com/in/maneshka-borham-ab5a787b/"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                {/* <a
                  class="share-btn share-btn-reddit"
                  href="http://www.reddit.com/submit?url=https://codepen.io/marko-zub/&amp;title=Marko+Zub+codepen"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306a.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487c-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0a.213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83c.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z" />
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325c-.801-.573-1.9-.945-3.121-.993l.534-2.501l1.738.372a.83.83 0 1 0 .83-.869a.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028a.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992c-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353c0 1.795 2.091 3.256 4.669 3.256c2.577 0 4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353c.401-.181.688-.592.688-1.069c0-.65-.525-1.165-1.165-1.165z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a> */}
                <a
                  class="share-btn share-btn-mail"
                  href="mailto:maneshka@citizen.lk%20"
                  rel="nofollow"
                  target="_blank"
                  title="via email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178l1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494l-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363L1.591 6.602l11.833-4.733Z" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a>
              </div>
            </div>
          </div>

          <p className="mt-3">
            Maneshka Borham joined Citizen Fact Check in March 2021 as its
            Associate Editor. A seasoned print media journalist turned
            fact-checker, prior to joining Citizen Fact Check she was the
            Assistant News Editor of Sunday Observer, a leading English language
            weekly in Sri Lanka. She holds an LLB degree from the University of
            London, and found her passion for journalism after joining the now
            defunct The Nation newspaper as a Trainee-journalist in 2013.
          </p>
          <p className="mt-2">
            During her career as a print media journalist, she focused mainly on
            the areas of human rights, defence, police and crime, environment
            and development. Having diligently followed numerous emblematic
            human rights related cases in Sri Lanka her journalistic work on
            human rights issues have been referenced by various leading advocacy
            organisations such as Amnesty International, International Crisis
            Group and in the latest Human Rights Watch report titled ‘Open
            Wounds and Mounting Dangers – Backing Accountability for Grave
            Abuses in Sri Lanka’. An avid feature writer, she is also a
            Co-Author of ‘Sri Lanka : Island of Islands’ published by The
            National Trust of Sri lanka.
          </p>

          <div className="row">
            <div className="col-md-4">
              <img src={sahan} style={{ width: "392px" }} />
            </div>
            <div className="col-md-8">
              <p className="mt-5">
                <b>Sahan Dahanayake</b>
              </p>
              <p>
                <b>Manager – Visual Content Citizen Media Network</b>
              </p>
              <div class="social-share-btns">
                <a
                  class="share-btn share-btn-twitter"
                  href="https://twitter.com/LiveSahan"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334c0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518a3.301 3.301 0 0 0 1.447-1.817a6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429a3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218a3.203 3.203 0 0 1-.865.115a3.23 3.23 0 0 1-.614-.057a3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                    />
                  </svg>
                  &nbsp;Tweet
                </a>
                <a
                  class="share-btn share-btn-facebook"
                  href="https://www.facebook.com/sahan.dahanayake.33"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131c.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                <a
                  class="share-btn share-btn-linkedin"
                  href="https://www.linkedin.com/in/sahan-dahanayake-089b491a7/"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                {/* <a
                  class="share-btn share-btn-reddit"
                  href="http://www.reddit.com/submit?url=https://codepen.io/marko-zub/&amp;title=Marko+Zub+codepen"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306a.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487c-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0a.213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83c.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z" />
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325c-.801-.573-1.9-.945-3.121-.993l.534-2.501l1.738.372a.83.83 0 1 0 .83-.869a.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028a.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992c-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353c0 1.795 2.091 3.256 4.669 3.256c2.577 0 4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353c.401-.181.688-.592.688-1.069c0-.65-.525-1.165-1.165-1.165z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a> */}
                <a
                  class="share-btn share-btn-mail"
                  href="mailto:sahan@citizen.lk"
                  rel="nofollow"
                  target="_blank"
                  title="via email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178l1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494l-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363L1.591 6.602l11.833-4.733Z" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a>
              </div>
            </div>
          </div>

          <p className="mt-3">
            Sahan Dahanayake, is a young but experienced journalist and content
            creator in both the broadcast and print media industry in Sri Lanka.
            Beginning his career at the Sinhalese language daily ‘Mawbima’ as a
            feature writer, Dahanayake later trying his hand at broadcast
            journalism joined the popular ‘Ada Derana 24X7’ television channel.
            At the television channel Dahanayake honed his skills going on to
            become a news editor and a program producer. Dahanayake, having
            gathered a vast experience in visual content creation and production
            is currently the Manager of Visual Content for Citizen Media
            Network. He has the herculean task of overseeing all social media
            platforms and visual content of the organisation including that of
            Citizen Fact Check, the fact checking arm of Citizen Media Network.
          </p>

          <div className="row">
            <div className="col-md-4">
              <img src={mifla} style={{ width: "392px" }} />
            </div>
            <div className="col-md-8">
              <p className="mt-5">
                <b>Mifla Bulkis Hinayathullah</b>
              </p>
              <p>
                <b>Junior Researcher and Fact-checker Citizen Fact Check</b>
              </p>
              <div class="social-share-btns">
                <a
                  class="share-btn share-btn-twitter"
                  href="https://twitter.com/LiveSahan"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334c0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518a3.301 3.301 0 0 0 1.447-1.817a6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429a3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218a3.203 3.203 0 0 1-.865.115a3.23 3.23 0 0 1-.614-.057a3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                    />
                  </svg>
                  &nbsp;Tweet
                </a>
                <a
                  class="share-btn share-btn-facebook"
                  href="https://www.facebook.com/sahan.dahanayake.33"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131c.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                <a
                  class="share-btn share-btn-linkedin"
                  href="https://www.linkedin.com/in/sahan-dahanayake-089b491a7/"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                    />
                  </svg>
                  &nbsp;Share
                </a>
                {/* <a
                  class="share-btn share-btn-reddit"
                  href="http://www.reddit.com/submit?url=https://codepen.io/marko-zub/&amp;title=Marko+Zub+codepen"
                  rel="nofollow"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306a.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487c-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0a.213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83c.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z" />
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325c-.801-.573-1.9-.945-3.121-.993l.534-2.501l1.738.372a.83.83 0 1 0 .83-.869a.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028a.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992c-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353c0 1.795 2.091 3.256 4.669 3.256c2.577 0 4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353c.401-.181.688-.592.688-1.069c0-.65-.525-1.165-1.165-1.165z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a> */}
                <a
                  class="share-btn share-btn-mail"
                  href="mailto:mifhinayath@gmail.com"
                  rel="nofollow"
                  target="_blank"
                  title="via email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178l1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494l-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363L1.591 6.602l11.833-4.733Z" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </g>
                  </svg>
                  &nbsp;Share
                </a>
              </div>
            </div>
          </div>

          <p className="mt-3">
            Mifla Hinayathullah, is a young Researcher and Law Graduate from
            University of London and has completed Diploma in Journalism at
            University of Colombo. She is enthusiastic of the field of
            Journalism and Research. She started off as an Intern Researcher at
            Verite Research Pvt Ltd and involved in Media Fact Check projects,
            Monitoring Print Media and content analysis. She has a passion for
            combining meticulous research and creative problem-solving to
            produce successful research outcomes for clients. Mifla, is
            currently a Junior Researcher at Citizen Media Network working for
            the platform Citizen Fact Check, the fact checking arm of Citizen
            Media Network.
          </p>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(OurTeam);
