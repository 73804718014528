import React, { Fragment, PureComponent } from "react";
// import Preview01 from "../../../img/Preview01.svg";
// import NoImg from "../../../img/no-img.jpg";
// import playlist from "../../../img/playlist.png";
import {
  getAllVideo,
  getLiveNews,
  getVideoByCategory,
} from "../../../store/actions/app-state-action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactPlayer from "react-player/youtube";
class VideoGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      videoList: [],
      live: [],
      isLive: false,
      success: false,
      token: "",
      videoId: 0,
      activeList: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      active: 0,
      politics: [],
      foreign: [],
      sports: [],
      business: [],
      category: false,
      show: [],
    };
  }

  componentDidMount() {
    this.props.getLiveNews();
    if (this.props.videoState.success && this.props.videoState.data) {
      this.setState({
        ...this.state,
        videoList: this.props.videoState.data,
        success: true,
      });
    } else {
      this.props.getAllVideo(0, 10);
    }
    if (!this.props.videoCategoryState["Politics"]) {
      this.props.getVideoByCategory("Politics", "Politics", 0, 10);
    } else {
      this.setState({
        politics: this.props.videoCategoryState["Politics"].data,
      });
    }

    if (!this.props.videoCategoryState["Foreign"]) {
      this.props.getVideoByCategory("Foreign", "Foreign", 0, 10);
    } else {
      this.setState({
        foreign: this.props.videoCategoryState["Foreign"].data,
      });
    }

    if (!this.props.videoCategoryState["Sports"]) {
      this.props.getVideoByCategory("Sports", "Sports", 0, 10);
    } else {
      this.setState({
        sports: this.props.videoCategoryState["Sports"].data,
      });
    }

    if (!this.props.videoCategoryState["Business"]) {
      this.props.getVideoByCategory("Business", "Business", 0, 10);
    } else {
      this.setState({
        business: this.props.videoCategoryState["Business"].data,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.videoState !== prevProps.videoState) {
      if (this.props.videoState.success) {
        this.setState({
          ...this.state,
          videoList: this.props.videoState.data,
          success: true,
        });
      }
    }

    if (this.props.liveState !== prevProps.liveState) {
      if (
        this.props.liveState.success &&
        this.props.liveState.data &&
        this.props.liveState.data[0]
      ) {
        this.setState({
          ...this.state,
          live: this.props.liveState.data[0],
          isLive:
            this.props.liveState.data[0].length !== 0 &&
            this.props.liveState.data[0].Status === "1"
              ? true
              : false,
        });
      }
    }

    if (this.props.videoCategoryState !== prevProps.videoCategoryState) {
      if (
        this.props.videoCategoryState["Politics"] &&
        this.props.videoCategoryState["Politics"].success
      ) {
        this.setState({
          politics: this.props.videoCategoryState["Politics"].data,
        });
      }

      if (
        this.props.videoCategoryState["Foreign"] &&
        this.props.videoCategoryState["Foreign"].success
      ) {
        this.setState({
          foreign: this.props.videoCategoryState["Foreign"].data,
        });
      }

      if (
        this.props.videoCategoryState["Sports"] &&
        this.props.videoCategoryState["Sports"].success
      ) {
        this.setState({
          sports: this.props.videoCategoryState["Sports"].data,
        });
      }

      if (
        this.props.videoCategoryState["Business"] &&
        this.props.videoCategoryState["Business"].success
      ) {
        this.setState({
          business: this.props.videoCategoryState["Business"].data,
          successForeign: true,
        });
      }
    }
  }

  handleVideoClick = (id) => {
    this.setState({ ...this.state, videoId: id });
  };

  handleCategorySwitch = (key, status, name, alias, id, prev, next) => {
    this.setState({
      ...this.state,
      show: this.state[key],
      name: name,
      id: id,
      alias: alias,
      activeList: { ...this.state.activeList, [prev]: false, [next]: true },
      active: next,
      videoList: this.state.videoList,
      live: this.state.live,
      category: key === "latest" ? false : true,
    });
  };

  handleOpenModal = (id) => {
    this.setState({
      ...this.state,
      videoId: parseInt(id),
    });
  };
  render() {
    if (!this.state.category) {
      var VideoGallery = this.state.videoList;
      if (this.state.live.length !== 0) {
        VideoGallery = [this.state.live].concat(VideoGallery);
      }
    } else {
      var VideoGallery = this.state.show;
    }

    return (
      <Fragment>
        <div className="video-gallery-wrapper" data-aos="fade-up">
          <div className="row p-0 mb-4 mx-0">
            <div className="col-12 col-sm-6 p-0">
              <p className="vg-title">Videos</p>
            </div>
            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end p-0">
              <p
                className={
                  this.state.activeList[0]
                    ? "vg-text-01 text-active"
                    : "vg-text-01"
                }
                onClick={(e) =>
                  this.handleCategorySwitch(
                    "latest",
                    this.state.success,
                    "Latest News",
                    "දැන් පුවත්",
                    8,
                    this.state.active,
                    0
                  )
                }
              >
                Latest
              </p>
              <p
                className={
                  this.state.activeList[1]
                    ? "vg-text-01 text-active"
                    : "vg-text-01"
                }
                onClick={(e) =>
                  this.handleCategorySwitch(
                    "politics",
                    this.state.successPolitics,
                    "Political News",
                    "දේශපාලන",
                    12,
                    this.state.active,
                    1
                  )
                }
              >
                Politics
              </p>
              <p
                className={
                  this.state.activeList[2]
                    ? "vg-text-01 text-active"
                    : "vg-text-01"
                }
                onClick={(e) =>
                  this.handleCategorySwitch(
                    "foreign",
                    this.state.successForeign,
                    "Foreign News",
                    "එතෙර",
                    10,
                    this.state.active,
                    2
                  )
                }
              >
                Foreign
              </p>
              <p
                className={
                  this.state.activeList[3]
                    ? "vg-text-01 text-active"
                    : "vg-text-01"
                }
                onClick={(e) =>
                  this.handleCategorySwitch(
                    "sports",
                    this.state.successSports,
                    "Sports News",
                    "කෙළි බිම",
                    11,
                    this.state.active,
                    3
                  )
                }
              >
                Sports
              </p>
              <p
                className={
                  this.state.activeList[4]
                    ? "vg-text-01 text-active"
                    : "vg-text-01"
                }
                onClick={(e) =>
                  this.handleCategorySwitch(
                    "business",
                    this.state.successBusiness,
                    "Business News",
                    "ආර්ථික",
                    2,
                    this.state.active,
                    4
                  )
                }
              >
                Business
              </p>
              {/* <p
                    className="vg-text-03"
                    onClick={(e) => this.handleCategory()}
                  >
                    View More
                  </p> */}
            </div>
          </div>
          <div className="row mx-0" style={{ backgroundColor: "black" }}>
            <div className="col-12 col-md-8 col-lg-7 p-2 p-md-3 p-lg-4">
              {this.state.success && VideoGallery.length !== 0 ? (
                <Fragment>
                  <ReactPlayer
                    className="video-player-container"
                    controls={true}
                    playing={this.state.isLive}
                    url={VideoGallery[this.state.videoId].URL}
                    width="100%"
                  />
                </Fragment>
              ) : this.state.active === 0 ? (
                <Skeleton variant="circle" height={400} />
              ) : (
                <p className="text-white">
                  There are no videos available under this category!
                </p>
              )}
            </div>
            <div className="col-12 col-md-4 col-lg-5 p-md-3 p-lg-4 video-list-container">
              {this.state.success ? (
                <Fragment>
                  <p className="vg-title-02">Playlist Name</p>
                  <div className="row m-0">
                    <p className="vg-text-subtext-01">Citizen News &nbsp;</p>
                    <p className="vg-text-subtext-02">
                      - {this.state.videoId + 1}/{VideoGallery.length}
                    </p>
                  </div>
                </Fragment>
              ) : null}

              {this.state.success &&
                VideoGallery.map((item, index) => {
                  var url_string = VideoGallery[index].URL; //window.location.href
                  // var url = new URL(url_string);
                  // var id = url.searchParams.get("v");
                  var url = new URL(url_string).pathname;

                  var id = url.replace("/", "");
                  return (
                    <Fragment key={index}>
                      <div
                        className="row "
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOpenModal(index)}
                      >
                        <div className="col-7 col-lg-3 m mt-2">
                          <div
                            className="playlist-img"
                            style={{
                              backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)`,
                            }}
                          ></div>
                        </div>
                        <div className="col-4 col-lg-9  pl-0 mt-2">
                          {this.state.isLive && index === 0 ? (
                            <p
                              className="vg-list-text-02"
                              onClick={(e) => this.handleOpenModal(index)}
                            >
                              Live
                              <i
                                className="fa fa-circle text-danger"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              {item.Title}
                            </p>
                          ) : (
                            <p className="vg-list-text-02 mt-2">{item.Title}</p>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoState: state.appStore.video,
    videoCategoryState: state.appStore.category,
    liveState: state.appStore.live,
    appState: state.appStore.login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllVideo: (from, to) => dispatch(getAllVideo(from, to)),
  getLiveNews: (from, to) => dispatch(getLiveNews(from, to)),
  getVideoByCategory: (data, key, from, to) =>
    dispatch(getVideoByCategory(data, key, from, to)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoGallery)
);
