import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import appStateReducer from "./reducers/app-state-reducer";
export const store = createStore(
  combineReducers({
    appStore: appStateReducer,
  }),
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);
