import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
class NonPartisanshipPolicy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Non-Partisanship Policy</h1>

          <p>
            Citizen Fact Check strives to ensure it practises unbiased and
            objective journalism. While CFC understands each individual may have
            their own political views, team members are expected to adhere to
            these ethics and values of the organisation. The fact checks should
            reflect impartiality, fairness and independence and therefore all
            articles are scrutinised for any forms of bias or political
            advocacy.
          </p>
          <p>How CFC ensures non-partisanship of team members :</p>
          <ul className="ml-3">
            <li>
              ● Team members are not allowed to be members of any political
              party or movement or to be affiliated with any such organizations.
            </li>
            <li>
              ● Political campaigning or other related activities within the
              organization or outside are not tolerated.
            </li>
            <li>
              ● Use of company property for political activities or advocacy is
              not allowed.
            </li>
            <li>
              ● Team members are not allowed to hold positions in government,
              political parties or organizations while employed at CFC.
            </li>
            <li>
              ● Team members are expected to sign a non-partisanship agreement
              during the on-boarding process.
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(NonPartisanshipPolicy);
