import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAllPosts,
  getSystemTheme,
  getSystemActiveTab,
} from "../../store/actions/app-state-action";
import moment from "moment";
import NoImg from "../../img/no-img.jpg";
import { API_STORAGE_BASE_URL } from "../../constants/api";
class ViewMoreArticles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      recent: [],
      tagPosts: [],
      tagSuccess: false,
      keyword: "",
      isDarkMode: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllPosts(0, 20);
    this.props.getSystemTheme();
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        recent: this.props.postsState.data,
        success: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
    if (this.props.postsState !== prevProps.postsState) {
      if (this.props.postsState.success) {
        if (this.props.postsState.data.length === 10) {
          this.props.getAllPosts(this.props.postsState.data.length + 1, 10);
        }
        this.setState({
          ...this.state,
          recent: this.props.postsState.data,
          success: true,
        });
      }
    }
  }

  handleRedirect = (id) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + id);
    window.top.location.href = `/focus-article.php?slug=` + id;
  };

  handleSearch = () => {
    if (this.state.keyword.length !== 0) {
      // this.props.history.push(`/citizen/search/` + this.state.keyword);
      window.top.location.href = `/citizen/search/` + this.state.keyword;
    }
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-sm-3" id="all-post-search-post-sm">
              <p>
                <b>Search Posts</b>
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search keywords"
                  aria-label="Search keywords"
                  aria-describedby="basic-addon2"
                  style={{ borderRight: "none" }}
                  id="keyword"
                  onChange={this.handleChange}
                  value={this.state.keyword}
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white"
                    id="basic-addon2"
                    onClick={(e) => this.handleSearch()}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <h4>ALL POSTS</h4>
              {this.state.success ? (
                this.state.recent.length !== 0 ? (
                  this.state.recent.map((item, index) => {
                    return (
                      <div
                        className="post-list-container mb-4"
                        key={index}
                        data-aos="fade-up"
                      >
                        <div
                          className="row mb-4 "
                          onClick={(e) => this.handleRedirect(item.Slug)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="col-6">
                            <img
                              className="post-list-img"
                              src={
                                item.Thumbnail.length !== 0 && item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              onError={this.addDefaultSrc}
                              alt="base"
                            />
                          </div>
                          <div className="col-6">
                            <p>CITIZEN FOCUS</p>
                            <p className="post-title">{item.Title}</p>
                            <p>
                              {moment(item.DateCreated)
                                .local("si")
                                .startOf("seconds")
                                .fromNow()}
                            </p>
                            <div
                              className="focus-article-text-span"
                              dangerouslySetInnerHTML={{
                                __html: item.Article[0],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h3>No posts were found under this tag!</h3>
                )
              ) : (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-3" id="all-post-search-post-md">
              <p>
                <b>Search Posts</b>
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search keywords"
                  aria-label="Search keywords"
                  aria-describedby="basic-addon2"
                  style={{ borderRight: "none" }}
                  id="keyword"
                  onChange={this.handleChange}
                  value={this.state.keyword}
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white"
                    id="basic-addon2"
                    onClick={(e) => this.handleSearch()}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postsState: state.appStore.posts,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewMoreArticles)
);
