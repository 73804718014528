import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../store/actions/app-state-action";
import { Link } from "react-router-dom";
class TermAndCondition extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Terms and Conditions</h1>
          <h6 className="mt-5 my-3 font-weight-bold">Introduction</h6>
          <p>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, Citizen Fact Check accessible
            at www.citizenfactcheck.lk
          </p>
          <p>
            These Terms will be applied fully and affect your use of this
            Website. By using this Website, you agreed to accept all terms and
            conditions written in here. You must not use this Website if you
            disagree with any of these Website Standard Terms and Conditions.
            These Terms and Conditions have been generated with the help of the
            Terms And Conditions Sample and the Privacy Policy Generator.
          </p>
          <p>
            Minors or people below 18 years old are not allowed to use this
            Website.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">
            Intellectual Property Rights
          </h6>
          <p>
            Other than the content you own, under these Terms, Citizen Fact
            Check and/or its licensors own all the intellectual property rights
            and materials contained in this Website.
          </p>
          <p>
            You are granted limited license only for purposes of viewing the
            material contained on this Website.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Restrictions</h6>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>Publishing any Website material in any other media;</li>
            <li>
              Selling, sublicensing and/or otherwise commercializing any Website
              material;
            </li>
            <li>Publicly performing and/or showing any Website material;</li>
            <li>
              Using this Website in any way that is or may be damaging to this
              Website;
            </li>
            <li>
              Using this Website in any way that impacts user access to this
              Website;
            </li>
            <li>
              Using this Website contrary to applicable laws and regulations, or
              in any way may cause harm to the Website, or to any person or
              business entity;
            </li>
            <li>
              Engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to this Website;
            </li>
            <li>
              Using this Website to engage in any advertising or marketing.
            </li>
          </ul>
          <p>
            Certain areas of this Website are restricted from being accessed by
            you and Citizen Fact Check may further restrict access by you to any
            areas of this Website, at any time, in absolute discretion. Any user
            ID and password you may have for this Website are confidential and
            you must maintain confidentiality as well.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Your Content</h6>
          <p>
            In these Website Standard Terms and Conditions, “Your Content” shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant
            Citizen Fact Check a non-exclusive, worldwide irrevocable,
            sub-licensable license to use, reproduce, adapt, publish, translate
            and distribute it in any and all media.
          </p>
          <p>
            Your Content must be your own and must not be invading any
            third-party’s rights. Citizen Fact Check reserves the right to
            remove any of Your Content from this Website at any time without
            notice.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Your Privacy</h6>
          <p>
            Please read{" "}
            <Link
              to={{
                pathname: "/",
              }}
              style={{ textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
            .
          </p>

          <h6 className="mt-5 my-3 font-weight-bold">No Warranties</h6>
          <p>
            This Website is provided “as is,” with all faults, and Citizen Fact
            Check express no representations or warranties, of any kind related
            to this Website or the materials contained on this Website. Also,
            nothing contained on this Website shall be interpreted as advising
            you.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">
            Limitation of Liability
          </h6>
          <p>
            In no event shall Citizen Fact Check, nor any of its officers,
            directors and employees, shall be held liable for anything arising
            out of or in any way connected with your use of this Website whether
            such liability is under contract. Citizen Fact Check, including its
            officers, directors and employees shall not be held liable for any
            indirect, consequential or special liability arising out of or in
            any way related to your use of this Website.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Indemnification</h6>
          <p>
            You hereby indemnify to the fullest extent Citizen Fact Check from
            and against any and/or all liabilities, costs, demands, causes of
            action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Severability</h6>
          <p>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Variation of Terms</h6>
          <p>
            Citizen Fact Check is permitted to revise these Terms at any time as
            it sees fit, and by using this Website you are expected to review
            these Terms on a regular basis.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Assignment</h6>
          <p>
            The Citizen Fact Check is allowed to assign, transfer, and
            subcontract its rights and/or obligations under these Terms without
            any notification. However, you are not allowed to assign, transfer,
            or subcontract any of your rights and/or obligations under these
            Terms.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">Entire Agreement</h6>
          <p>
            These Terms constitute the entire agreement between Citizen Fact
            Check and you in relation to your use of this Website, and supersede
            all prior agreements and understandings.
          </p>
          <h6 className="mt-5 my-3 font-weight-bold">
            Governing Law & Jurisdiction
          </h6>
          <p>
            These Terms will be governed by and interpreted in accordance with
            the laws of Sri Lanka and you submit to the non-exclusive
            jurisdiction of the courts located in Sri Lanka for the resolution
            of any disputes.
          </p>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(TermAndCondition);
