import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
class ContactUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Contact Us</h1>

          <p>
            Our team would love to hear from you. Mail us at hello@citizen.lk or
            the address below if you wish to contribute (knowledge, insights et
            al) or join our team or just tell us how we can do better.
          </p>

          <ul>
            <li>Citizen Media Network (Pvt) Ltd</li>
            <li>Address : 96, 8A Kottanuwatta Road, Nugegoda 10250</li>
            <li>T: +94 112810532</li>
            <li>M: +94764412285</li>

            <li>
              Using this Website to engage in any advertising or marketing.
            </li>
          </ul>
          <p className="mt-5 mb-2">
            Send us WhatsApp message{" "}
            <a href="https://api.whatsapp.com/message/VJP6TCV4K4M7B1?autoload=1&app_absent=0">
              here
            </a>
          </p>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(ContactUs);
