import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
class CorrectionsAndComplaintsPolicy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>Corrections And Complaints Policy</h1>

          <p>
            Mistakes happen. Fact Checkers are not an exception to this
            universal truth. Honest errors are a part of publishing even after
            the application of due diligence procedures.
          </p>
          <p>
            While at Citizen Fact Check every effort is made to ensure that our
            fact-checks represent correct, latest and the most relevant data, we
            believe how we deal with errors in our fact checks is important to
            maintaining our integrity and the trust of our readers and sources.
          </p>
          <p>
            When Citizen Fact Check publishes an error and it is brought to our
            notice whether by our staffers or our readers, we will acknowledge
            it and take appropriate steps to correct it right away on all
            platforms Citizen Fact Check has a presence on.
          </p>

          <p>
            Readers are also welcome to submit any complaints on our editorial
            material. We are also open to constructive feedback and criticism.
          </p>
          <p>
            In case you find a discrepancy or an error, please write to our
            editors at hello@citizen.lk or maneshka@citizen.lk with CORRECTION
            or COMPLAINT as the subject line.
          </p>

          <p>
            Alternately members of the public can also forward any requests for
            review of published information by contacting us through WhatsApp on
            +94 76 693 2784 or through any of our social media accounts.
          </p>
          <p>
            {" "}
            The team will address any bona fide requests for corrections or
            complaints right away, and provide an update to the complainant.
          </p>
          <p>
            In addition, if any new information comes to light after we publish
            a Fact Check that materially changes that story, we will clarify,
            correct or update our story. In all cases, we will provide a note to
            readers that explains the change, why it was made and the date it
            was made.
          </p>
          <p>
            Any corrections we make to fact-checks are prominently acknowledged
            and will feature the tags CORRECTION or UPDATE within the article
            and on new social media posts relating to the correction or update.{" "}
          </p>
          <p>
            Below are examples of articles updated with an Editor’s note in line
            with Citizen Fact Check’s correction policy :
          </p>

          <figure class="wp-block-embed is-type-wp-embed is-provider-citizen-fact-check-sri-lanka wp-block-embed-citizen-fact-check-sri-lanka">
            <div class="wp-block-embed__wrapper">
              <blockquote class="wp-embedded-content" data-secret="n7xsRKCpuX">
                <a href="https://citizenfactcheck.lk/live-fact-check-president-gotabaya-address-to-the-nation/">
                  LIVE Fact Check : President Gotabaya address to the nation
                </a>
              </blockquote>
              <iframe
                class="wp-embedded-content"
                sandbox="allow-scripts"
                security="restricted"
                title="&#8220;LIVE Fact Check : President Gotabaya address to the nation&#8221; &#8212; Citizen Fact Check -  Sri Lanka"
                src="https://citizenfactcheck.lk/live-fact-check-president-gotabaya-address-to-the-nation/embed/#?secret=n7xsRKCpuX"
                data-secret="n7xsRKCpuX"
                width="600"
                height="338"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
                scrolling="no"
              ></iframe>
            </div>
          </figure>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(
  null,
  mapDispatchToProps
)(CorrectionsAndComplaintsPolicy);
