import {
  ACTION_GET_ALL_FACT_CHECK,
  ACTION_GET_FACT_CHECK_BY_ID,
  ACTION_GET_ALL_FACT_CHECK_ARTICLE,
  ACTION_GET_FACT_CHECK_ARTICLE_BY_ID,
  ACTION_GET_TRENDING_POSTS,
  ACTION_GET_ALL_POSTS,
  ACTION_GET_POST_BY_ID,
  ACTION_GET_POST_BY_SEARCH,
  ACTION_GET_POST_BY_TAG,
  ACTION_SUBSCRIBED,
  ACTION_GET_POST_SLUG,
  ACTION_GET_FACT_SLUG,
  ACTION_GET_SYSTEM_THEME,
  ACTION_GET_SYSTEM_ACTIVE_TAB,
  ACTION_ADD_VIEW_COUNT,
  ACTION_GET_ADS,
  ACTION_ADD_COUNT,
  ACTION_GET_VIDEOS,
  ACTION_GET_LIVE_NEWS,
  ACTION_GET_VIDEO_BY_CATEGORY,
} from "../../constants/actions";

const initialState = {
  posts: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  post: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  factCheckList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  factCheck: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    status: 0,
  },
  factCheckArticleList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  factCheckArticle: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    status: 0,
  },
  trendingPosts: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  search: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  tags: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  newsletter: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  systemTheme: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  systemActiveTab: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  viewCount: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  ads: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  count: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  video: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  live: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  category: [],
};

export default function appStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_POSTS: {
      if (action.status) {
        _state = {
          ...state,
          posts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: state.posts.data.concat(action.data),
            status: action.status,
          },
        };
      } else {
        _state = {
          ...state,
          posts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: state.posts.data,
            status: action.status,
          },
        };
      }
      break;
    }

    case ACTION_GET_ALL_FACT_CHECK: {
      _state = {
        ...state,
        factCheckList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_FACT_CHECK_BY_ID: {
      _state = {
        ...state,
        factCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_GET_ALL_FACT_CHECK_ARTICLE: {
      _state = {
        ...state,
        factCheckArticleList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_FACT_CHECK_ARTICLE_BY_ID: {
      _state = {
        ...state,
        factCheckArticle: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_GET_TRENDING_POSTS: {
      _state = {
        ...state,
        trendingPosts: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_POST_BY_ID: {
      _state = {
        ...state,
        post: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_POST_BY_TAG: {
      _state = {
        ...state,
        tags: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_POST_BY_SEARCH: {
      _state = {
        ...state,
        search: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_SUBSCRIBED: {
      _state = {
        ...state,
        newsletter: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_POST_SLUG: {
      _state = {
        ...state,
        post: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_FACT_SLUG: {
      _state = {
        ...state,
        factCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_SYSTEM_THEME: {
      _state = {
        ...state,
        systemTheme: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_SYSTEM_ACTIVE_TAB: {
      _state = {
        ...state,
        systemActiveTab: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_ADD_VIEW_COUNT: {
      _state = {
        ...state,
        viewCount: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_ADS: {
      _state = {
        ...state,
        ads: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_ADD_COUNT: {
      _state = {
        ...state,
        count: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_VIDEOS: {
      _state = {
        ...state,
        video: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_LIVE_NEWS: {
      _state = {
        ...state,
        live: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_VIDEO_BY_CATEGORY: {
      _state = {
        ...state,
        category: {
          ...state.category,
          [action.key]: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: state.category[action.key]
              ? state.category[action.key].data.concat(action.data)
              : action.data,
            status: action.status,
          },
        },
      };
      break;
    }
    default: {
      _state = state;
      break;
    }
  }

  return _state;
}
