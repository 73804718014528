import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import NoImg from "../../../img/no-img.jpg";
import {
  subscribe,
  getAllPosts,
  getSystemTheme,
  getSystemActiveTab,
  addCount,
  getAllAds,
  getLiveNews,
  getAllVideo,
} from "../../../store/actions/app-state-action";
import play_circle from "../../../img/play_circle.svg";
import calender from "../../../img/calender.svg";
import moment from "moment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { API_STORAGE_BASE_URL } from "../../../constants/api";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactPlayer from "react-player/youtube";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
class Citizenfocus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      success: false,
      checked: false,
      email: "",
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
      isDarkMode: false,
      adList: [],
      videoList: [],
      open: false,
      videoId: 0,
      live: [],
      isLive: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.videoState !== prevProps.videoState) {
      if (this.props.videoState.success) {
        this.setState({
          ...this.state,
          videoList: this.props.videoState.data,
          success: true,
        });
      }
    }
    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          ...this.state,
          posts: this.props.postState.data,
          success: true,
        });
      }
    }
    if (this.props.newsletterState !== prevProps.newsletterState) {
      if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 1
      ) {
        this.showSuccess();
      } else if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 0
      ) {
        this.showError(this.props.newsletterState.message);
      } else {
        this.showError(this.props.newsletterState.message);
      }
    }
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
    if (this.props.liveState !== prevProps.liveState) {
      if (
        this.props.liveState.success &&
        this.props.liveState.data &&
        this.props.liveState.data[0]
      ) {
        this.setState({
          ...this.state,
          live: this.props.liveState.data[0],
          isLive:
            this.props.liveState.data[0].length !== 0 &&
            this.props.liveState.data[0].Status === "1"
              ? true
              : false,
        });
      }
    }
  }

  componentDidMount() {
    // localStorage.setItem("SAT", 1);
    // this.props.getSystemActiveTab();
    this.props.getLiveNews();
    this.props.getAllVideo(0, 10);
    this.props.getSystemTheme();
    this.props.getAllAds();
    if (this.props.postState.success) {
      this.setState({
        ...this.state,
        posts: this.props.postState.data,
        success: true,
      });
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleCheckBox = () => {
    this.setState({ ...this.state, checked: !this.state.checked });
  };
  handleOpenModal = (index) => {
    this.setState({ ...this.state, open: true, videoId: index });
  };
  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.location.assign(url);
  };
  handleSubscribe = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );

    const dateCreated = moment().format("YYYY-MM-DD hh:mm:ss");
    if (this.state.email.length !== 0) {
      if (!pattern.test(this.state.email)) {
        this.showError("Invalid email address inserted!");
        return false;
      }
      this.setState({ ...this.state, spinner: true });
      this.props.subscribe({ email: this.state.email, dateCreated });
    } else {
      this.showError("Email address cannot be empty!");
    }
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: this.props.newsletterState.message,
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/");
          window.top.location.href = `/`;
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };
  handleRedirect = (id) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + id);
    window.top.location.href = `/focus-article.php?slug=` + id;
    document.getElementById("collapseExample").classList.remove("show");
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }
  handleLoadMore = () => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push("/citizen/article/all");
    window.top.location.href = `/citizen/article/all`;
    document.getElementById("collapseExample").classList.remove("show");
  };

  render() {
    const { email, checked } = this.state;
    if (!this.state.category) {
      var VideoGallery = this.state.videoList;
      if (this.state.live.length !== 0) {
        VideoGallery = [this.state.live].concat(VideoGallery);
      }
    } else {
      var VideoGallery = this.state.show;
    }

    return (
      <Fragment>
        <div className="col-12  col-sm-12 mb-3 " data-aos="fade-left">
          {this.state.success
            ? this.state.posts &&
              this.state.posts.length !== 0 &&
              this.state.posts
                .map((item, index) => {
                  return (
                    <Fragment>
                      <div
                        key={index}
                        className="row"
                        onClick={(e) => this.handleRedirect(item.Slug)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="col-4 col-sm-3">
                          <div className="card" style={{ border: "none" }}>
                            <img
                              className="citizen-focus-img-01 "
                              src={
                                item.Thumbnail.length !== 0 && item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              onError={this.addDefaultSrc}
                            />
                          </div>
                        </div>
                        <div className="col-8 col-sm-9">
                          {/* <button
                              type="button"
                              className="btn btn-sm latest-news-button"
                            >
                              {item.Category}
                            </button> */}
                          <div className="row pl-3 pr-3 latest-news-subtext">
                            <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                            <p className="ln-text-date">
                              {moment(item.DateCreated)
                                .local("si")
                                .startOf("seconds")
                                .fromNow()}
                            </p>
                          </div>
                          <p className="citizen-focus-text-01">
                            {item.Title.length > 100
                              ? `${item.Title.substring(0, 100)}...`
                              : item.Title}
                          </p>
                        </div>
                      </div>
                      <hr
                        className={
                          this.state.isDarkMode ? "dark-hr-post" : "hr-post "
                        }
                      />
                    </Fragment>
                  );
                })
                .slice(0, 5)
            : null}
          {this.state.posts.length !== 0 ? (
            <Fragment>
              <div
                className="col-12 d-flex justify-content-center align-item-center"
                id="focus-load-more-btn-sm"
              >
                <button
                  type="button"
                  name=""
                  id=""
                  class="btn btn-lg  load-more-btn"
                  onClick={(e) => this.handleLoadMore()}
                >
                  LOAD MORE
                </button>
              </div>
            </Fragment>
          ) : null}
        </div>

        {this.state.posts.length !== 0 ? (
          <Fragment>
            <div
              className="col-12 d-flex justify-content-center"
              id="focus-load-more-btn-md"
            >
              <button
                type="button"
                name=""
                id=""
                class="btn btn-lg  load-more-btn"
                onClick={(e) => this.handleLoadMore()}
              >
                LOAD MORE
              </button>
            </div>
          </Fragment>
        ) : null}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div style={{ width: "75%", height: "75%" }}>
              {this.state.success && this.state.videoList.length !== 0 ? (
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={VideoGallery[this.state.videoId].URL}
                />
              ) : null}
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsletterState: state.appStore.newsletter,
    postState: state.appStore.posts,
    systemThemeState: state.appStore.systemTheme,
    adState: state.appStore.ads,
    videoState: state.appStore.video,
    liveState: state.appStore.live,
  };
};

const mapDispatchToProps = (dispatch) => ({
  subscribe: (data) => dispatch(subscribe(data)),
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
  addCount: (data) => dispatch(addCount(data)),
  getAllAds: () => dispatch(getAllAds()),
  getAllVideo: (from, to) => dispatch(getAllVideo(from, to)),
  getLiveNews: (from, to) => dispatch(getLiveNews(from, to)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Citizenfocus)
);
