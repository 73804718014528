import api from "../services/base-services";
import {
  API_GET_ALL_FACT_CHECK,
  API_GET_FACT_CHECK_BY_ID,
  API_GET_ALL_FACT_CHECK_ARTICLE,
  API_GET_FACT_CHECK_ARTICLE_BY_ID,
  API_GET_TRENDING_POSTS,
  API_GET_ALL_POSTS,
  API_GET_POST_BY_ID,
  API_GET_POST_SEARCH,
  API_GET_POST_BY_TAG,
  API_POST_NEWSLETTER_SUBSCRIBE,
  API_GET_POST_SLUG,
  API_GET_FACT_SLUG,
  API_ADD_VIEW_COUNT,
  API_GET_ADS,
  API_POST_COUNT,
  API_GET_ALL_VIDEO,
  API_GET_LIVE_NEWS,
  API_GET_VIDEO_BY_CATEGORY,
} from "../constants/api";

export default class AppServices {
  static getAll(lan, from, to) {
    return api.get(API_GET_ALL_FACT_CHECK, {
      params: {
        language: lan,
        from: from,
        to: to,
      },
    });
  }

  static getAllPosts(from, to) {
    return api.get(API_GET_ALL_POSTS, { params: { from: from, to: to } });
  }

  static getById(data) {
    return api.get(API_GET_FACT_CHECK_BY_ID, { params: { id: data } });
  }

  static getAllArticle(from, to) {
    return api.get(API_GET_ALL_FACT_CHECK_ARTICLE, {
      params: {
        from: from,
        to: to,
      },
    });
  }

  static getArticleById(data) {
    return api.get(API_GET_FACT_CHECK_ARTICLE_BY_ID, { params: { id: data } });
  }

  static getTrendingPosts(data) {
    return api.get(API_GET_TRENDING_POSTS, {
      params: { value: data },
    });
  }

  static getPostById(ID) {
    return api.get(API_GET_POST_BY_ID, { params: { title: ID } });
  }

  static getPostBySearch(data) {
    return api.get(API_GET_POST_SEARCH, {
      params: { keyword: data },
    });
  }

  static getPostByTag(data) {
    return api.get(API_GET_POST_BY_TAG, {
      params: { tag: data },
    });
  }

  static subscribe(data) {
    return api.post(API_POST_NEWSLETTER_SUBSCRIBE, data);
  }

  static getPostBySlug(slug) {
    return api.get(API_GET_POST_SLUG, { params: { slug: slug } });
  }

  static getFactBySlug(slug) {
    return api.get(API_GET_FACT_SLUG, { params: { slug: slug } });
  }
  static addViewCount(data) {
    return api.post(API_ADD_VIEW_COUNT, data);
  }
  static getAllAds() {
    return api.get(API_GET_ADS);
  }
  static addCount(data) {
    return api.post(API_POST_COUNT, data);
  }
  static getAllVideo(from, to) {
    return api.get(API_GET_ALL_VIDEO, { params: { from: from, to: to } });
  }

  static getLiveNews() {
    return api.get(API_GET_LIVE_NEWS);
  }
  static getVideoByCategory(data, from, to) {
    return api.get(API_GET_VIDEO_BY_CATEGORY, {
      params: { value: data, from: from, to: to },
    });
  }
}
