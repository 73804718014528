import React, { PureComponent, Fragment } from "react";

import { getAll, getSystemTheme } from "../../store/actions/app-state-action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import verified from "../../img/verified.svg";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import TrafficLightCheckLogo from "../../img/Traffic-Light-Check-Logo-vertical.png";

class FactCheckArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAllFactCheck: true,
      isFactCheck: false,
      isProgress: false,
      isFalse: false,
      data: [],
      factCheck: [],
      loading: true,
      success: false,
      isLoading: true,
      isFactSearch: false,
      factSearchText: "",
      isDarkMode: false,
    };
  }

  componentDidMount() {
    var language = window.localStorage.getItem("Lan");

    this.props.getAll(language, 0, 10);
    this.props.getSystemTheme();
  }

  componentDidUpdate(prevState) {
    if (prevState.factCheckState !== this.props.factCheckState) {
      if (
        this.props.factCheckState.success &&
        !this.props.factCheckState.isLoading
      ) {
        this.setState({
          ...this.state,
          factCheck: this.props.factCheckState.data,
          isLoading: false,
          success: true,
        });
      }
    }

    if (this.props.systemThemeState !== prevState.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
  }

  handleAllFactCheck = () => {
    this.setState({
      ...this.state,
      isAllFactCheck: true,
      isFactCheck: false,
      isProgress: false,
      isFalse: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleFactCheck = () => {
    this.setState({
      ...this.state,
      isFactCheck: true,
      isProgress: false,
      isFalse: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleProgress = () => {
    this.setState({
      ...this.state,
      isProgress: true,
      isFactCheck: false,
      isFalse: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleFalse = () => {
    this.setState({
      ...this.state,
      isFalse: true,
      isProgress: false,
      isFactCheck: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleRedirect = (id) => {
    // this.props.history.push(`/article/${id}`);
    window.top.location.href = `/article.php?slug=` + id;
  };

  handleSearch = () => {
    var facts = [];
    if (this.state.isFactSearch) {
      this.setState({
        ...this.state,
        isFactSearch: false,
        factSearchText: "",
        factCheck: this.props.factCheckState.data
          ? this.props.factCheckState.data
          : [],
      });
    } else {
      if (this.state.factSearchText) {
        facts = this.props.factCheckState.data
          ? this.props.factCheckState.data.filter((fact, index) =>
              fact.Title.toLowerCase().includes(
                this.state.factSearchText.toLowerCase()
              )
            )
          : [];

        this.setState({ ...this.state, isFactSearch: true, factCheck: facts });
      }
    }
  };

  render() {
    const override = css`
      border-color: white;
      position: absolute;
      left: 5px;
    `;
    return (
      <Fragment>
        <div className="news-feed-button">
          <div className="row d-flex mx-0 mb-1">
            <div className="col-12 col-md-6 col-sm-12 p-0  d-flex justify-content-center">
              {/* <h4>Fact Check</h4> */}

              <img
                className="article-fact-check-header-logo-1 my-auto"
                src={TrafficLightCheckLogo}
                style={{ width: "155px" }}
                alt="TrafficLightCheckLogo"
              />
            </div>
            <div className="col-12 col-md-6 col-sm-12 d-flex flex-row mx-0 p-0">
              <input
                className="form-control w-100 fact-search-input"
                type="text"
                placeholder="Search here"
                value={this.state.factSearchText}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    factSearchText: e.target.value,
                  })
                }
                disabled={this.state.isFactSearch}
              />
              <button
                type="button"
                className="fact-search-btn ml-2"
                onClick={() => this.handleSearch()}
              >
                {this.state.isFactSearch ? (
                  <i className="fa fa-times" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-search" aria-hidden="true"></i>
                )}
              </button>
            </div>
          </div>
          <div className="news-feed-button fact-check-md-btn">
            <div
              className="btn-group special"
              role="group"
              aria-label="..."
              style={{ overflowX: "auto" }}
            >
              <button
                type="button"
                className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isAllFactCheck ? "#4C4E4C" : "#191A19"
                  }`,
                }}
                onClick={(e) => this.handleAllFactCheck()}
              >
                All
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg  mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isFactCheck ? "#7FA87D" : "#3B7937"
                  }`,
                }}
                onClick={(e) => this.handleFactCheck()}
              >
                Verified
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isProgress ? "#F2BB82" : "#EB963F"
                  }`,
                }}
                onClick={(e) => this.handleProgress()}
              >
                &nbsp;{" "}
                <PuffLoader
                  color="white"
                  loading={true}
                  css={override}
                  size={25}
                />
                In Progress{" "}
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg btn-md  d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isFalse ? "#D37A78" : "#BC332F"
                  }`,
                }}
                onClick={(e) => this.handleFalse()}
              >
                False/Misleading
              </button>
            </div>
          </div>
          <div className="news-feed-button fact-check-sm-btn">
            <div
              className="btn-group special"
              role="group"
              aria-label="..."
              style={{ overflowX: "auto" }}
            >
              <button
                type="button"
                className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isAllFactCheck ? "#4C4E4C" : "#191A19"
                  }`,
                }}
                onClick={(e) => this.handleAllFactCheck()}
              >
                All
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg  mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isFactCheck ? "#7FA87D" : "#3B7937"
                  }`,
                }}
                onClick={(e) => this.handleFactCheck()}
              >
                Verified
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isProgress ? "#F2BB82" : "#EB963F"
                  }`,
                }}
                onClick={(e) => this.handleProgress()}
              >
                WIP{" "}
                <PuffLoader
                  color="white"
                  loading={true}
                  css={override}
                  size={20}
                />
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg btn-md  d-flex justify-content-center"
                style={{
                  backgroundColor: `${
                    !this.state.isFalse ? "#D37A78" : "#BC332F"
                  }`,
                }}
                onClick={(e) => this.handleFalse()}
              >
                False
              </button>
            </div>
          </div>
          {this.state.isAllFactCheck ? (
            <div
              className="card fact-check-card"
              style={{ border: "2px solid rgb(76 78 76)" }}
            >
              <div className="card-body fact-check-body">
                {this.state.isLoading ? (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      <Skeleton variant="circle" height={30} width={30} />
                    </div>
                    <div className="col-10 col-md-10 pl-0 pl-md-3">
                      <p className="fact-check-text-01">
                        <Skeleton variant="rect" height={20} />
                      </p>
                      <p className="fact-check-subtext">
                        <Skeleton variant="rect" height={14} width={75} />
                      </p>
                      <p className="fact-check-text-02">
                        <Skeleton variant="rect" height={50} />
                      </p>
                    </div>
                  </div>
                ) : this.state.factCheck.length > 0 ? (
                  this.state.factCheck.map((item, index) => {
                    return (
                      <div className="row mx-0" key={index}>
                        <div className="col-2 col-md-2">
                          {item.Status === "1" ? (
                            <i
                              class="fas fa-badge-check"
                              style={{
                                fontSize: "20px",
                                color: "#3B7937",
                                marginTop: "6px",
                              }}
                            ></i>
                          ) : item.Status === "2" ? (
                            <i
                              className="fa fa-refresh text-warning"
                              aria-hidden="true"
                              style={{
                                fontSize: "18px",
                                color: "#EB963F",
                                marginTop: "6px",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                fontSize: "20px",
                                color: "#BC332F",
                                marginTop: "6px",
                              }}
                            ></i>
                          )}
                        </div>
                        <div
                          className="col-10 col-md-10 pl-0"
                          onClick={(e) => this.handleRedirect(item.Slug)}
                        >
                          <p className="fact-check-text-01">
                            Fact Check: {item.Title}
                          </p>
                          <p className="fact-check-subtext">
                            {item.Source} &nbsp;
                            {moment(item.DateCreated).format(
                              "DD MMMM YYYY, hh:mm:ss A"
                            )}
                          </p>
                          <p
                            className="fact-check-text-02"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.Description.length > 368
                                  ? `${item.Description.substring(0, 368)}...`
                                  : item.Description,
                            }}
                          ></p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="row mx-0 d-flex justify-content-center">
                    <label className="factCheck-text-dark ">
                      There are no facts.
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {this.state.isFactCheck ? (
            <div className="card fact-check-card">
              <div className="card-body fact-check-body">
                {this.state.isLoading ? (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      <Skeleton variant="circle" height={30} width={30} />
                    </div>
                    <div className="col-10 col-md-10 pl-0 ">
                      <p className="fact-check-text-01">
                        <Skeleton variant="rect" height={20} />
                      </p>
                      <p className="fact-check-subtext">
                        <Skeleton variant="rect" height={14} width={75} />
                      </p>
                      <p className="fact-check-text-02">
                        <Skeleton variant="rect" height={50} />
                      </p>
                    </div>
                  </div>
                ) : this.state.factCheck.filter((f) => f.Status === "1")
                    .length > 0 ? (
                  this.state.factCheck.map((item, index) => {
                    if (item.Status === "1") {
                      return (
                        <div className="row mx-0" key={index}>
                          <div className="col-2 col-md-2">
                            <i
                              class="fas fa-badge-check"
                              style={{
                                fontSize: "20px",
                                color: "#3B7937",
                                marginTop: "6px",
                              }}
                            ></i>
                          </div>
                          <div
                            className="col-10 col-md-10 pl-0 "
                            onClick={(e) => this.handleRedirect(item.Slug)}
                          >
                            <p className="fact-check-text-01">
                              Fact Check: {item.Title}
                            </p>
                            <p className="fact-check-subtext">
                              {item.Source} &nbsp;
                              {moment(item.DateCreated).format(
                                "DD MMMM YYYY, hh:mm:ss A"
                              )}
                            </p>
                            <p
                              className="fact-check-text-02"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.Description.length > 368
                                    ? `${item.Description.substring(0, 368)}...`
                                    : item.Description,
                              }}
                            ></p>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <div className="row mx-0 d-flex justify-content-center">
                    <label className="factCheck-text-dark ">
                      There are no facts.
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {this.state.isProgress ? (
            <div className="card progress-card">
              <div className="card-body fact-check-body">
                {this.state.isLoading ? (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      <Skeleton variant="circle" height={30} width={30} />
                    </div>
                    <div className="col-10 col-md-10 pl-0">
                      <p className="fact-check-text-01">
                        <Skeleton variant="rect" height={20} />
                      </p>
                      <p className="fact-check-subtext">
                        <Skeleton variant="rect" height={14} width={75} />
                      </p>
                      <p className="fact-check-text-02">
                        <Skeleton variant="rect" height={50} />
                      </p>
                    </div>
                  </div>
                ) : this.state.factCheck.filter((f) => f.Status === "2")
                    .length > 0 ? (
                  this.state.factCheck.map((item, index) => {
                    if (item.Status === "2") {
                      return (
                        <div className="row mx-0" key={index}>
                          <div className="col-2 col-md-2">
                            <i
                              className="fa fa-refresh text-warning"
                              aria-hidden="true"
                              style={{
                                fontSize: "18px",
                                color: "#EB963F",
                                marginTop: "6px",
                              }}
                            ></i>
                          </div>
                          <div
                            className="col-10 col-md-10 pl-0 "
                            onClick={(e) => this.handleRedirect(item.Slug)}
                          >
                            <p className="fact-check-text-01">
                              Fact Check: {item.Title}
                            </p>
                            <p className="fact-check-subtext">
                              {item.Source} &nbsp;
                              {moment(item.DateCreated).format(
                                "DD MMMM YYYY, hh:mm:ss A"
                              )}
                            </p>
                            <p
                              className="fact-check-text-02"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.Description.length > 368
                                    ? `${item.Description.substring(0, 368)}...`
                                    : item.Description,
                              }}
                            ></p>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="row mx-0 d-flex justify-content-center">
                    <label className="factCheck-text-dark ">
                      There are no facts.
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {this.state.isFalse ? (
            <div className="card false-card">
              <div className="card-body fact-check-body">
                {this.state.isLoading ? (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      <Skeleton variant="circle" height={30} width={30} />
                    </div>
                    <div className="col-10 col-md-10 pl-0">
                      <p className="fact-check-text-01">
                        <Skeleton variant="rect" height={20} />
                      </p>
                      <p className="fact-check-subtext">
                        <Skeleton variant="rect" height={14} width={75} />
                      </p>
                      <p className="fact-check-text-02">
                        <Skeleton variant="rect" height={50} />
                      </p>
                    </div>
                  </div>
                ) : this.state.factCheck.filter((f) => f.Status === "3")
                    .length > 0 ? (
                  this.state.factCheck.map((item, index) => {
                    if (item.Status === "3") {
                      return (
                        <div className="row mx-0" key={index}>
                          <div className="col-2 col-md-2">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                fontSize: "20px",
                                color: "#BC332F",
                                marginTop: "6px",
                              }}
                            ></i>
                          </div>
                          <div
                            className="col-10 col-md-10 pl-0 "
                            onClick={(e) => this.handleRedirect(item.Slug)}
                          >
                            <p className="fact-check-text-01">
                              Fact Check: {item.Title}
                            </p>
                            <p className="fact-check-subtext">
                              {item.Source} &nbsp;
                              {moment(item.DateCreated).format(
                                "DD MMMM YYYY, hh:mm:ss A"
                              )}
                            </p>
                            <p
                              className="fact-check-text-02"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.Description.length > 368
                                    ? `${item.Description.substring(0, 368)}...`
                                    : item.Description,
                              }}
                            ></p>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="row mx-0 d-flex justify-content-center">
                    <label className="factCheck-text-dark ">
                      There are no facts.
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    factCheckState: state.appStore.factCheckList,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAll: (lan, from, to) => dispatch(getAll(lan, from, to)),
  getSystemTheme: () => dispatch(getSystemTheme()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactCheckArticle)
);
