import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAll,
  getSystemActiveTab,
  addCount,
  getAllAds,
} from "../../store/actions/app-state-action";
import FactCheck from "./components/FactCheck";
import Subscribe from "./components/Subscribe";
import Trendingnews from "./components/TrendingNews";
import Citizenfocus from "./components/CitizenFocus";
import NoImg from "../../img/no-img.jpg";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import Skeleton from "@material-ui/lab/Skeleton";
import Carousel from "./components/Carousel";
import VideoGallery from "./components/VideoGallery";

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFactCheck: true,
      isProgress: false,
      isFalse: false,
      factCheckLisk: [],
      success: false,
      posts: [],
      data: [],
      adList: [],
    };
  }

  componentDidMount() {
    this.props.getAllAds();
    localStorage.setItem("SAT", 0);
    this.props.getSystemActiveTab();
    if (this.props.factCheckState.success) {
      this.setState({
        ...this.state,
        factCheckLisk: this.props.factCheckState.data,
      });
    } else {
      var language = window.localStorage.getItem("Lan");
      this.props.getAll(language, 0, 10);
    }

    if (this.props.postState.success) {
      this.setState({
        ...this.state,
        posts: this.props.postState.data,
        data: this.props.postState.data,
        success: true,
      });
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.factCheckState !== this.props.factCheckState) {
      if (this.props.factCheckState.success) {
        this.setState({
          ...this.state,
          factCheckLisk: this.props.factCheckState.data,
        });
      }
    }

    if (this.props.postState !== preProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          ...this.state,
          posts: this.props.postState.data,
          data: this.props.postState.data,
          success: true,
        });
      }
    }
    if (preProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }

  handleFactCheck = () => {
    this.setState({
      ...this.state,
      isFactCheck: true,
      isProgress: false,
      isFalse: false,
    });
  };

  handleProgress = () => {
    this.setState({
      ...this.state,
      isProgress: true,
      isFactCheck: false,
      isFalse: false,
    });
  };

  handleFalse = () => {
    this.setState({
      ...this.state,
      isFalse: true,
      isProgress: false,
      isFactCheck: false,
    });
  };

  handleRedirect = (name) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + name);
    window.top.location.href = `/focus-article.php?slug=` + name;
    document.getElementById("collapseExample").classList.remove("show");
  };

  handleLoadMore = () => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push("/citizen/article/all");
    window.top.location.href = `/citizen/article/all`;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }
  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.location.assign(url);
  };
  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12  pl-3 pl-sm-0 mt-3">
              <FactCheck />
            </div>

            <div className="col-12 col-xl-6 col-lg-6 col-md-12  col-sm-12 mt-3  focus-col">
              <h4 className="mb-3">Citizen Focus</h4>
              <div className="row mx-0  ">
                {this.state.data.length && this.state.success ? (
                  <Citizenfocus />
                ) : (
                  <Skeleton variant="rect" height={414} />
                )}
              </div>
            </div>

            <div
              className="col-12 p-sm-0"
              // style={{ width: "300px", height: "250px" }}
            >
              {/* <div id="block_57"></div> */}
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 01") {
                    return (
                      <div className="ad mt-5 mb-5 mx-0" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail.toString()}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-12 p-sm-0">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 02") {
                    return (
                      <div className="ad mt-5 mb-5 mx-0" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail.toString()}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
              {/* <div id="block_57"></div> */}
            </div>

            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 mx-0">
              <h4 className="mb-3">Editors’ Picks</h4>
              <div className="col-12 order-1 order-sm-3 px-0">
                {this.state.data.length && this.state.success ? (
                  <Fragment>
                    <div
                      id="carouselId1"
                      className="carousel slide mb-5"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                          <div className="row">
                            {this.state.success &&
                              this.state.data.length > 0 &&
                              this.state.data
                                .map((item, index) => {
                                  return (
                                    <div
                                      className={
                                        index === 0
                                          ? "col-12 col-sm-4  mt-3"
                                          : "col-12 col-sm-4 pl-0 mt-3"
                                      }
                                      data-aos="fade-up"
                                      key={index}
                                    >
                                      <div
                                        className="card latest-news-card  h-100 "
                                        onClick={(e) =>
                                          this.handleRedirect(item.Slug)
                                        }
                                      >
                                        <div className="latest-news-img-container">
                                          <img
                                            className="latest-news-img"
                                            onError={this.addDefaultSrc}
                                            src={
                                              item.Thumbnail.length !== 0 &&
                                              item.Thumbnail
                                                ? API_STORAGE_BASE_URL +
                                                  item.Thumbnail
                                                : NoImg
                                            }
                                            style={{
                                              cursor: "pointer",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                        <div className="card-body p-3">
                                          <button
                                            type="button"
                                            className="btn btn-sm latest-news-button"
                                          >
                                            {item.Category}
                                          </button>
                                          <div className="row pl-3 pr-3 latest-news-subtext">
                                            <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                            <p className="ln-text-date">
                                              {moment(item.DateCreated)
                                                .local("si")
                                                .startOf("seconds")
                                                .fromNow()}
                                            </p>
                                          </div>
                                          <p className="ln-text-01">
                                            {item.Title.length > 60
                                              ? item.Title.slice(0, 60) + "..."
                                              : item.Title}
                                          </p>
                                        </div>
                                        {/* <div className="row new-img-info mx-0">
                                          <div className="col-12 my-auto">
                                            <p className="news-img-text-01">
                                              {moment(item.DateCreated)
                                                .local("si")
                                                .startOf("seconds")
                                                .fromNow()}
                                            </p>
                                          </div>
                                          <div className="col-12 mb-4">
                                            {this.state.success ? (
                                              <div
                                                className="news-img-text-02"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item.Title.length > 100
                                                      ? `${item.Title.substring(
                                                          0,
                                                          100
                                                        )}...`
                                                      : item.Title,
                                                }}
                                              ></div>
                                            ) : null}
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  );
                                })
                                .slice(0, 3)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Skeleton variant="rect" height={414} />
                )}
              </div>
            </div>
          </div>

          <Trendingnews />
        </div>
        {/* <div className="fact-check-wrapper-video" data-aos="fade-up"> */}
        <VideoGallery />
        {/* </div> */}
        {/* <div className="subscribe-container" data-aos="fade-up">
          <Subscribe />
        </div> */}
        <div className="fact-check-wrapper" data-aos="fade-up">
          <h4 className="mb-3 ">Latest News</h4>
          <Carousel />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    factCheckState: state.appStore.factCheckList,
    postState: state.appStore.posts,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAll: (lan, from, to) => dispatch(getAll(lan, from, to)),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
  addCount: (data) => dispatch(addCount(data)),
  getAllAds: () => dispatch(getAllAds()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
