import React, { PureComponent, Fragment } from "react";
// Icon
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TodayIcon from "@material-ui/icons/Today";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import {
  getPostById,
  getPostByTag,
  getPostBySlug,
  getAllPosts,
  getSystemTheme,
  getSystemActiveTab,
  addViewCount,
  addCount,
  getAllAds,
} from "../../store/actions/app-state-action";
import { withRouter } from "react-router-dom";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import moment from "moment";
import "moment/locale/en-au";
import NoImg from "../../img/no-img.jpg";
import MDEditor from "@uiw/react-md-editor";

class FocusArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: {},
      success: false,
      open: false,
      recent: [],
      tagPosts: [],
      tagSuccess: false,
      isDarkMode: false,
      url: this.props.match.params.name,
      adList: [],
    };
  }

  componentDidMount() {
    this.props.getAllAds();
    document.getElementById("collapseExample").classList.remove("show");
    this.props.getAllPosts(0, 12);
    this.props.getSystemTheme();
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    window.scrollTo(0, 0);
    var post = this.props.postsState.data.filter(
      (x) => x.Title === this.props.match.params.name.toString()
    );
    if (post.length !== 0 && this.props.postsState.success) {
      this.setState({
        ...this.state,
        news: post[0],
        recent: this.props.postsState.data,
        success: true,
      });
      if (post[0].Tags.length !== 0) {
        var posts = post[0].Tags.sort(
          (a, b) => parseFloat(b.count) - parseFloat(a.count)
        );
        // this.props.getPostByTag(posts[0].name);
      }
      if (!JSON.parse(window.sessionStorage.getItem("english_post_list"))) {
        window.sessionStorage.setItem(
          "english_post_list",
          JSON.stringify([post[0].Id])
        );
        this.props.addViewCount({ id: post[0].Id });
      } else {
        var arr = JSON.parse(
          window.sessionStorage.getItem("english_post_list")
        );
        if (!arr.includes(post[0].Id)) {
          arr.push(post[0].Id);
          // update count
          window.sessionStorage.setItem(
            "english_post_list",
            JSON.stringify(arr)
          );
          this.props.addViewCount({ id: post[0].Id });
        }
      }
    } else {
      this.props.getPostBySlug(this.props.match.params.name);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.setState({
        ...this.state,
        success: false,
        url: this.props.match.params.name,
      });
      var post = this.props.postsState.data.filter(
        (x) => x.Title === this.props.match.params.name.toString()
      );
      if (post.length !== 0 && this.props.postsState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          recent: this.props.postsState.data,
          success: true,
        });
        if (!JSON.parse(window.sessionStorage.getItem("english_post_list"))) {
          window.sessionStorage.setItem(
            "english_post_list",
            JSON.stringify([post[0].Id])
          );
          this.props.addViewCount({ id: post[0].Id });
        } else {
          var arr = JSON.parse(
            window.sessionStorage.getItem("english_post_list")
          );
          if (!arr.includes(post[0].Id)) {
            arr.push(post[0].Id);
            // update count
            window.sessionStorage.setItem(
              "english_post_list",
              JSON.stringify(arr)
            );
            this.props.addViewCount({ id: post[0].Id });
          }
        }
        if (post[0].Tags.length !== 0) {
          var posts = post[0].Tags.sort(
            (a, b) => parseFloat(b.count) - parseFloat(a.count)
          );
          this.props.getPostByTag(posts[0].name);
        }
      } else {
        this.props.getPostBySlug(this.props.match.params.name);
      }
    }

    if (this.props.postState !== prevProps.postState) {
      if (
        this.props.postState.success &&
        this.props.postState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          news: this.props.postState.data[0],
          success: true,
        });
        if (!JSON.parse(window.sessionStorage.getItem("english_post_list"))) {
          window.sessionStorage.setItem(
            "english_post_list",
            JSON.stringify([this.props.postState.data[0].Id])
          );
          this.props.addViewCount({ id: this.props.postState.data[0].Id });
        } else {
          var arr = JSON.parse(
            window.sessionStorage.getItem("english_post_list")
          );
          if (!arr.includes(this.props.postState.data[0].Id)) {
            arr.push(this.props.postState.data[0].Id);
            // update count
            window.sessionStorage.setItem(
              "english_post_list",
              JSON.stringify(arr)
            );
            this.props.addViewCount({ id: this.props.postState.data[0].Id });
          }
        }
        if (this.props.postState.data[0].Tags.length !== 0) {
          var posts = this.props.postState.data[0].Tags.sort(
            (a, b) => parseFloat(b.count) - parseFloat(a.count)
          );
          this.props.getPostByTag(posts[0].name);
        }
      }
    }

    if (this.props.postsState !== prevProps.postsState) {
      if (
        this.props.postsState.success &&
        this.props.postsState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          recent: this.props.postsState.data,
          success: true,
        });
      }
    }

    if (this.props.tagState !== prevProps.tagState) {
      if (this.props.tagState.success) {
        this.setState({
          ...this.state,
          tagPosts: this.props.tagState.data.filter(
            (x) => x.Title !== this.props.match.params.name
          ),
          tagSuccess: true,
        });
      }
    }
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
  }

  handleRedirect = (name) => {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    this.setState({
      ...this.state,
      tagSuccess: false,
      tagPosts: [],
    });
    // this.props.history.push(`/citizen/focus/` + name);
    window.top.location.href = `/focus-article.php?slug=` + name;
    document.getElementById("collapseExample").classList.remove("show");
  };

  handleTagRedirect = (name) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/tags/` + name);
    window.top.location.href = `/citizen/tags/` + name;
    document.getElementById("collapseExample").classList.remove("show");
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.location.assign(url);
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-sm-8 pl-3 pl-sm-0">
              <button
                type="button"
                class="btn btn-secondary btn-sm article-label mb-3"
              >
                Citizen Focus
              </button>
              {this.state.success ? (
                <p className="fc-article-title">{this.state.news.Title}</p>
              ) : (
                <Skeleton variant="rect" height={144} />
              )}
              <div className="row  mx-0 my-2 d-flex align-items-center">
                {this.state.success ? (
                  <div className="row mx-0 ">
                    <TodayIcon />
                    <p className="pl-2 pr-3 mb-0">
                      {moment(this.state.news.DateCreated).format(
                        "DD MMMM YYYY"
                      )}
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}
                {this.state.success ? (
                  <div className="row mx-0 p-1 mr-2">
                    <QueryBuilderIcon />
                    <p className="ml-2 mb-0">
                      {moment(this.state.news.DateCreated)
                        .local("si")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}

                <div className="dropdown d-flex justify-content-center align-items-center  ">
                  <button
                    className="btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "white" }}
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="row d-flex justify-content-start mb-3 ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "https://api.whatsapp.com/send?text=" +
                          this.state.news.Title +
                          "...https://fc.citizen.lk/focus-article.php?slug=" +
                          this.state.news.Slug
                        }
                        data-action="share/whatsapp/share"
                        style={{ textDecoration: "none" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-whatsapp "
                            aria-hidden="true"
                            style={{ color: "#2E7D32" }}
                          ></i>
                        </button>
                        WhatsApp
                      </a>
                    </div>
                    <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "https://www.facebook.com/sharer/sharer.php?u=https://fc.citizen.lk/focus-article.php?slug=" +
                          this.state.news.Slug
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-facebook "
                            aria-hidden="true"
                            style={{ color: "#36528C" }}
                          ></i>
                        </button>
                        Facebook
                      </a>
                    </div>

                    <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "http://twitter.com/share?text=" +
                          this.state.news.Title +
                          "&url=https://fc.citizen.lk/focus-article.php?slug=" +
                          this.state.news.Slug
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-twitter"
                            aria-hidden="true"
                            style={{ color: "#55ACEE" }}
                          ></i>
                        </button>
                        Twitter
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.state.success ? (
                    <img
                      className="article-img"
                      src={
                        this.state.news.Thumbnail &&
                        this.state.news.Thumbnail.length !== 0
                          ? API_STORAGE_BASE_URL + this.state.news.Thumbnail
                          : NoImg
                      }
                      onError={this.addDefaultSrc}
                      alt="Article"
                    />
                  ) : (
                    <Skeleton variant="rect" height={430} />
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 article-text pr-2 pr-sm-5 ">
                  {/* {this.state.success ? (
                    <Fragment>
                      <p>
                        <i>By {this.state.news.Author}</i>
                      </p>
                    </Fragment>
                  ) : (
                    <Skeleton
                      className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                      variant="rect"
                      height={30}
                      width={150}
                    />
                  )} */}
                  {this.state.success ? (
                    <Fragment>
                      {this.state.news.Article &&
                        this.state.news.Article.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              {/* <div
                                className="focus-article-text-span"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.news.Article[index],
                                }}
                              ></div> */}
                              <MDEditor.Markdown
                                source={this.state.news.Article[index]}
                                style={{
                                  whiteSpace: "pre-wrap",
                                  backgroundColor: "white",
                                  color: "black",
                                }}
                              />
                              <br />
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  ) : (
                    <Skeleton variant="rect" height={184} />
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {this.state.success ? (
                    <button
                      type="button"
                      className={
                        this.state.isDarkMode
                          ? "btn btn-sm tag-btn mx-0 mr-3 mt-2"
                          : "btn btn-sm tag-btn px-0 mr-3 mt-2"
                      }
                    >
                      <i
                        className="fa fa-tags mr-2 mt-2"
                        aria-hidden="true"
                      ></i>
                      Tags
                    </button>
                  ) : (
                    <Skeleton
                      className="btn btn-sm tag-btn-list btn-light"
                      variant="rect"
                      height={30}
                      width={75}
                    />
                  )}

                  {this.state.success ? (
                    this.state.news.Tags &&
                    this.state.news.Tags.length !== 0 ? (
                      this.state.news.Tags.sort(
                        (a, b) => parseFloat(b.count) - parseFloat(a.count)
                      ).map((item, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            className="btn btn-sm tag-btn-list btn-light mr-3 mt-2"
                            onClick={(e) => this.handleTagRedirect(item.name)}
                          >
                            {item.name}
                          </button>
                        );
                      })
                    ) : null
                  ) : (
                    <Skeleton
                      className="btn btn-sm tag-btn-list btn-light ml-3"
                      variant="rect"
                      height={30}
                      width={75}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4 mt-3">
              <p>
                <b>Recent Posts</b>
              </p>
              {this.state.recent &&
                this.state.recent.length !== 0 &&
                this.state.recent
                  .filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i)
                  .map((item, index) => {
                    return (
                      <div
                        className="row mb-3"
                        key={index}
                        onClick={(e) => this.handleRedirect(item.Slug)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="col-12">
                          {item.Title}
                          <hr
                            className={
                              this.state.isDarkMode
                                ? "dark-hr-post"
                                : "hr-post "
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                  .slice(0, 10)}
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 06") {
                    return (
                      <Fragment key={index}>
                        <div className="card article-ad-right">
                          <img
                            src={
                              API_STORAGE_BASE_URL + item.Thumbnail.toString()
                            }
                            alt="advertisement"
                            onClick={(e) =>
                              this.handleCount(item.Id, item.Count, item.Url)
                            }
                            onError={this.addDefaultSrc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Fragment>
                    );
                  }
                })}
            </div>
            <div className="col-12">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 07") {
                    return (
                      <div className="ad mt-4 mb-4" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail.toString()}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-12  p-0 mt-4">
              {this.state.tagSuccess &&
              this.state.tagPosts.length !== 0 &&
              this.state.tagPosts.filter((item) => item.Slug !== this.state.url)
                .length ? (
                <p>Related Posts</p>
              ) : null}

              <div className="row">
                {this.state.tagSuccess &&
                  this.state.tagPosts.length !== 0 &&
                  this.state.tagPosts
                    .filter((item) => item.Slug !== this.state.url)
                    .map((item, index) => {
                      return (
                        <Fragment>
                          <div
                            className="col-12 col-md-4 col-lg-3"
                            onClick={(e) => this.handleRedirect(item.Slug)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            <div class="card citzen-focused-card-02 bg-light">
                              <img
                                src={
                                  item.Thumbnail.length !== 0 && item.Thumbnail
                                    ? API_STORAGE_BASE_URL + item.Thumbnail
                                    : NoImg
                                }
                                onError={this.addDefaultSrc}
                                alt="citizen-focus"
                              />
                            </div>
                            <p className="citzen-focused-text-01 mt-4">
                              Citizen Focus : {item.Category}
                            </p>
                            <p className="citzen-focused-heading-02">
                              {item.Title.length > 200
                                ? `${item.Title.substring(0, 200)}...`
                                : item.Title}
                            </p>
                          </div>
                        </Fragment>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.appStore.post,
    postsState: state.appStore.posts,
    tagState: state.appStore.tags,
    systemThemeState: state.appStore.systemTheme,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostById: (id) => dispatch(getPostById(id)),
  getPostByTag: (id) => dispatch(getPostByTag(id)),
  getPostBySlug: (id) => dispatch(getPostBySlug(id)),
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
  addViewCount: (data) => dispatch(addViewCount(data)),
  addCount: (data) => dispatch(addCount(data)),
  getAllAds: () => dispatch(getAllAds()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FocusArticle)
);
