import React, { Component, Fragment } from "react";
import {
  subscribe,
  getSystemActiveTab,
} from "../../../store/actions/app-state-action";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      email: "",
      success: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
    };
  }

  componentDidMount() {
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.newsletterState !== prevProps.newsletterState) {
      if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 1
      ) {
        this.showSuccess();
      } else if (
        this.props.newsletterState.success &&
        this.props.newsletterState.data === 0
      ) {
        this.showError(this.props.newsletterState.message);
      } else {
        this.showError(this.props.newsletterState.message);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleCheckBox = () => {
    this.setState({ ...this.state, checked: !this.state.checked });
  };

  handleSubscribe = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );

    const dateCreated = moment().format("YYYY-MM-DD hh:mm:ss");
    if (this.state.email.length !== 0) {
      if (!pattern.test(this.state.email)) {
        this.showError("Invalid email address inserted!");
        return false;
      }
      this.setState({ ...this.state, spinner: true });
      this.props.subscribe({ email: this.state.email, dateCreated });
    } else {
      this.showError("Email address cannot be empty!");
    }
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: this.props.newsletterState.message,
        checked: false,
        email: "",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };
  render() {
    const { email, checked } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 text-center">
            <p className="subscription-text-01 m-auto">
              Get all in one.
              <br /> Subscribe to our weekly newsletter.
            </p>
            <p className="subscription-text-02 m-auto">
              A NEWSLETTER THAT BRINGS YOU ALL THE MAJOR NEWS OF THE WEEK:
              <br /> THE BIG, THE TRENDING, THE STUFF YOU MISSED, THE BIZARRE.
              JUST TEN MINUTES EVERY SATURDAY MORNING. AS REPORTED BY MANESHKA
              BORHAM.
            </p>
          </div>
        </div>
        <div className="newsletter-container mx-auto my-5">
          <div className="row">
            <div className="col-12 mb-3">
              <label for="email" style={{ color: "white" }}>
                Email Address
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                aria-describedby="helpId"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12">
              <div className="form-check text-white">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name=""
                    id=""
                    value="checkedValue"
                    checked={checked}
                    onClick={() => this.handleCheckBox()}
                  />
                  Agree to terms and conditions
                </label>
              </div>
            </div>
            <div className="col-12 mb-3">
              <Link
                to={{
                  pathname: "/termsandconditions",
                }}
                style={{ textDecoration: "none" }}
              >
                <small>Read terms and conditions</small>
              </Link>
            </div>
            <div className="col-12 mb-3">
              {this.state.isError ? (
                <div className="alert alert-danger w-100" role="alert">
                  {this.state.errorMsg}
                </div>
              ) : this.state.spinner ? (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : this.state.isValid ? (
                <div className="alert alert-success w-100" role="alert">
                  {this.state.validMsg}
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#ff6763",
                        color: "white",
                      }}
                      disabled={!checked}
                      onClick={() => this.handleSubscribe()}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsletterState: state.appStore.newsletter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  subscribe: (data) => dispatch(subscribe(data)),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
