import React, { PureComponent, Fragment } from "react";
import {
  getTrendingPosts,
  getSystemActiveTab,
} from "../../../store/actions/app-state-action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import NoImg from "../../../img/no-img.jpg";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import { API_STORAGE_BASE_URL } from "../../../constants/api";
import Skeleton from "@material-ui/lab/Skeleton";

class Trendingnews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      trending: [],
      success: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          ...this.state,
          trending: this.props.postState.data,
          success: true,
        });
      }
    }
  }

  componentDidMount() {
    if (!this.props.postState || !this.props.postState.success) {
      this.props.getTrendingPosts(0); //Current News
    } else {
      this.setState({
        ...this.state,
        trending: this.props.postState.data,
        success: true,
      });
    }
  }

  handleRedirect = (title) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push("/citizen/focus/" + title);
    window.top.location.href = `/focus-article.php?slug=` + title;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    if (this.state.success) {
      return (
        <Fragment>
          <h4 className="mb-3 mt-5">Trending News</h4>
          <div
            id="relatedCarouselId"
            className="carousel slide mt-5 trending-md"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="row">
                  {this.state.success &&
                    this.state.trending

                      .map((item, index) => {
                        return (
                          <div className="col-4" data-aos="fade-up" key={index}>
                            <div
                              className="card latest-news-card  h-100 "
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  onError={this.addDefaultSrc}
                                  src={
                                    item.Thumbnail.length !== 0 &&
                                    item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  style={{
                                    cursor: "pointer",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm latest-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                  <p className="ln-text-date">
                                    {moment(item.DateCreated)
                                      .local("si")
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <p className="ln-text-01">
                                  {item.Title.length > 60
                                    ? item.Title.slice(0, 60) + "..."
                                    : item.Title}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(0, 3)}
                </div>
              </div>

              {this.state.success && this.state.trending.length > 3 && (
                <div className="carousel-item">
                  <div className="row">
                    {this.state.trending
                      .map((item, index) => {
                        return (
                          <div className="col-4" data-aos="fade-up" key={index}>
                            <div
                              className="card latest-news-card  h-100 "
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  onError={this.addDefaultSrc}
                                  src={
                                    item.Thumbnail.length !== 0 &&
                                    item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  style={{
                                    cursor: "pointer",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm latest-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                  <p className="ln-text-date">
                                    {moment(item.DateCreated)
                                      .local("si")
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <p className="ln-text-01">
                                  {item.Title.length > 60
                                    ? item.Title.slice(0, 60) + "..."
                                    : item.Title}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(3, 6)}
                  </div>
                </div>
              )}
              {this.state.success && this.state.trending.length > 6 && (
                <div className="carousel-item">
                  <div className="row">
                    {this.state.trending
                      .map((item, index) => {
                        return (
                          <div className="col-4" data-aos="fade-up" key={index}>
                            <div
                              className="card latest-news-card  h-100 "
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  onError={this.addDefaultSrc}
                                  src={
                                    item.Thumbnail.length !== 0 &&
                                    item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  style={{
                                    cursor: "pointer",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm latest-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                  <p className="ln-text-date">
                                    {moment(item.DateCreated)
                                      .local("si")
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <p className="ln-text-01">
                                  {item.Title.length > 60
                                    ? item.Title.slice(0, 60) + "..."
                                    : item.Title}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(6, 9)}
                  </div>
                </div>
              )}
            </div>

            {this.state.trending.length > 0 ? (
              <Fragment>
                <a
                  className="carousel-control-prev"
                  href="#relatedCarouselId"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#relatedCarouselId"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </Fragment>
            ) : null}
          </div>

          <div
            id="carouselExampleControls"
            className=" carousel slide trending-sm"
            data-ride="carousel"
            data-aos="fade-up"
          >
            <div class="carousel-inner ">
              {this.state.trending.length !== 0 &&
                this.state.trending
                  .map((item, index) => {
                    return (
                      <div
                        class={
                          index === 0
                            ? "carousel-item card news-img-card active"
                            : "carousel-item card news-img-card "
                        }
                        onClick={(e) => this.handleRedirect(item.Slug)}
                      >
                        <div
                          className="card latest-news-card  h-100 "
                          onClick={(e) => this.handleRedirect(item.Slug)}
                        >
                          <div className="latest-news-img-container">
                            <img
                              className="latest-news-img"
                              onError={this.addDefaultSrc}
                              src={
                                item.Thumbnail.length !== 0 && item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              style={{
                                cursor: "pointer",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div className="card-body p-3">
                            <button
                              type="button"
                              className="btn btn-sm latest-news-button"
                            >
                              {item.Category}
                            </button>
                            <div className="row pl-3 pr-3 latest-news-subtext">
                              <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                              <p className="ln-text-date">
                                {moment(item.DateCreated)
                                  .local("si")
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                            <p className="ln-text-01">
                              {item.Title.length > 60
                                ? item.Title.slice(0, 60) + "..."
                                : item.Title}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  .slice(0, 9)}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div
            id="relatedCarouselId"
            className="carousel slide mt-5 mb-5"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.trending.length > 0 ? (
              <Fragment>
                <a
                  className="carousel-control-prev"
                  href="#relatedCarouselId"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#relatedCarouselId"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </Fragment>
            ) : null}
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.appStore.trendingPosts,
    // postState: state.appStore.posts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrendingPosts: (data) => dispatch(getTrendingPosts(data)),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Trendingnews)
);
