import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NoImg from "../../img/no-img.jpg";
import moment from "moment";
import {
  getSystemActiveTab,
  getSystemTheme,
} from "../../store/actions/app-state-action";
import { API_STORAGE_BASE_URL } from "../../constants/api";
class CitizenFocus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: {},
      success: false,
      open: false,
      posts: [],
      tagSuccess: false,
      isDarkMode: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getSystemTheme();
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        posts: this.props.postsState.data,
        success: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        posts: this.props.postsState.data,
        success: true,
      });
    }
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }

    if (this.props.postsState !== prevProps.postsState) {
      if (
        this.props.postsState.success &&
        this.props.postsState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          posts: this.props.postsState.data,
          success: true,
        });
      }
    }
  }

  handleRedirect = (name) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + name);
    window.top.location.href = `/focus-article.php?slug=` + name;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-md-8">
              {this.state.success &&
                this.state.posts.map((item, index) => {
                  if (index === 0) {
                    return (
                      <div
                        className="citizen-focus-container"
                        key={index}
                        onClick={(e) => this.handleRedirect(item.Slug)}
                      >
                        <img
                          className="citizen-focus-img"
                          src={
                            item.Thumbnail.length !== 0
                              ? API_STORAGE_BASE_URL + item.Thumbnail
                              : NoImg
                          }
                          style={{
                            cursor: "pointer",
                          }}
                          onError={this.addDefaultSrc}
                        />
                        <div className="citizen-focus-info">
                          <button type="button" class="btn category-text">
                            {item.Category}
                          </button>
                          <p className="focus-main-title">{item.Title}</p>
                          <p className="focus-main-date">
                            {moment(item.DateCreated).format("DD MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-4">
                <div className="col-6">
                  <h3 className="latest-label">The Latest</h3>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <h3 className="latest-label">
                    {moment().format("DD MMMM YYYY")}
                  </h3>
                </div>
              </div>
              <div className="row">
                {this.state.posts.filter(
                  (f) =>
                    moment(f.DateCreated).format("MMM DD, YYYY") ===
                    moment().format("MMM DD, YYYY")
                ).length !== 0 ? (
                  this.state.posts
                    .filter(
                      (f) =>
                        moment(f.DateCreated).format("MMM DD, YYYY") ===
                        moment().format("MMM DD, YYYY")
                    )
                    .filter(
                      (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i
                    )
                    .map((item, index) => {
                      return (
                        <div className="col-12 mt-3" key={index}>
                          <div
                            className="row"
                            onClick={(e) => this.handleRedirect(item.Slug)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="col-8">
                              <p className="citzen-focused-text-01">
                                Citizen Focus
                              </p>
                              <p className="citzen-focused-heading-02">
                                {item.Title}
                              </p>
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                              <div className="trending-news-img-container align-self-center">
                                <img
                                  className="trending-news-img"
                                  src={
                                    item.Thumbnail.length !== 0 &&
                                    item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  onError={this.addDefaultSrc}
                                  alt="trending news"
                                />
                              </div>
                            </div>
                            {/* <div className="col-12 mt-3">
                            <p>
                              <div
                                className="focus-article"
                                dangerouslySetInnerHTML={{
                                  __html: item.Article[0],
                                }}
                              />
                            </p>
                          </div> */}
                          </div>
                        </div>
                      );
                    })
                    .slice(0, 6)
                ) : (
                  <div className="col-12 mt-3">
                    <div className="row mx-0 d-flex justify-content-center">
                      <label
                        className={
                          this.state.isDarkMode
                            ? "factCheck-text-white  text-center"
                            : "factCheck-text-dark text-center"
                        }
                      >
                        There are no latest news.
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postsState: state.appStore.posts,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
  getSystemTheme: () => dispatch(getSystemTheme()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CitizenFocus)
);
