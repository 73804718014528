export const WEB_BASE = "https://fc.citizen.lk";

// export const API_BASE_URL =
//   "http://localhost/citizen-api-release-v1/citizen-api";
// export const API_STORAGE_BASE_URL =
//   "http://localhost/citizen-api-release-v1/citizen-api";

// export const API_BASE_URL = "https://citizen.lk/fc-api";
// export const API_STORAGE_BASE_URL = "https://citizen.lk/fc-api";

// export const API_BASE_URL = "http://apis.codebrixtech.com";
// export const API_STORAGE_BASE_URL = "http://apis.codebrixtech.com";

// export const API_BASE_URL = "https://citizen.codebrixtech.biz/apis";
// export const API_STORAGE_BASE_URL = "https://citizen.codebrixtech.biz/apis";

// export const API_BASE_URL = "https://www.breakingnow.lk/api";
// export const API_STORAGE_BASE_URL = "https://www.breakingnow.lk/api";

export const API_BASE_URL = "https://api.citizen.lk";
export const API_STORAGE_BASE_URL = "https://api.citizen.lk";

export const API_LOGIN = "/v1/adminAuth/login/";
export const API_GET_REFRESH_TOKEN = "/v1/adminAuth/refreshToken/";

export const API_GET_ALL_FACT_CHECK = "/v1/factCheck/all/";
export const API_GET_FACT_CHECK_BY_ID = "/v1/factCheck/";

export const API_GET_ALL_FACT_CHECK_ARTICLE = "/v1/factCheckArticle/all/";
export const API_GET_FACT_CHECK_ARTICLE_BY_ID = "/v1/factCheckArticle/";

export const API_GET_ALL_POSTS = "/v1/englishNews/posts/getAll/";
export const API_GET_TRENDING_POSTS = "/v1/englishNews/posts/trendingNews/";
export const API_GET_POST_BY_ID = "/v1/englishNews/posts/postByName/";
export const API_GET_POST_SEARCH = "/v1/englishNews/posts/postSearch/";
export const API_GET_POST_BY_TAG = "/v1/englishNews/posts/postByTags/";
export const API_POST_NEWSLETTER_SUBSCRIBE = "/v1/newsletter/";

export const API_GET_POST_SLUG = "/v1/englishNews/posts/postBySlug/";
export const API_GET_FACT_SLUG = "/v1/factCheck/factBySlug/";

export const API_ADD_VIEW_COUNT = "/v1/englishNews/posts/addViewCount/";
export const API_GET_ADS = "/v1/advertisement/all/";
export const API_POST_COUNT = "/v1/advertisement/addCount/";
export const API_GET_ALL_VIDEO = "/v1/videoGallery/all/";

export const API_GET_LIVE_NEWS = "/v1/liveVideoFeed/all/";
export const API_GET_VIDEO_BY_CATEGORY = "/v1/videoGallery/videoByCategory/";
