import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getSystemActiveTab } from "../../../store/actions/app-state-action";
import { Link } from "react-router-dom";
class AboutUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 3);
    this.props.getSystemActiveTab();
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <h1>About Us</h1>

          <p>
            Citizen Fact Check, dedicated to fact checking, is the first of its
            kind in Sri Lanka to be established by a local media network.
            Citizen Fact Check launched its beta version on March 1, 2021 to
            scrutinize and research for veracity and identify the context of
            viral social media posts on various platforms. These include but are
            not limited to statements made by individuals in public life and
            other data that is in the public domain. By April 2, 2021 Citizen
            Fact Check became a separate website apart from its sister site
            Citizen.lk which earlier hosted the Citizen Fact Check widget.
          </p>
          <p>
            Citizen Fact Check (www.citizenfactcheck.lk) is an initiative and
            fact checking unit of Sri Lankan digital media house Citizen Media
            Network, which also holds ownership of Citizen.lk and Your News.
          </p>
          <p>
            Citizen Media Network is located at 96, 8A Kottanuwatta Road,
            Nugegoda 10250 , Sri Lanka and is registered as a private limited
            company (Company Registration No. PV 00240614) under the Sri Lanka
            Companies Act No 7 of 2007.
          </p>
          <div className="row mx-0 p-0">
            <div className="col-12 d-flex justify-content-center">
              <iframe
                src="https://drive.google.com/file/d/17r2OFkmsib1RAvAIBmcINoo42LRPVggR/preview"
                width="640"
                height="480"
                className="d-flex justify-content-center"
                allow="autoplay"
              ></iframe>
            </div>
          </div>

          <h4 className="mt-5 my-3 font-weight-bold">Funding </h4>
          <p>
            <b>March 2020</b> – Citizen Fact Check was launched last year and
            the initial funding required was provided by Digital Content (Pvt)
            Ltd a Creative, Digital Content, Video Production & Digital
            Marketing Communications Agency which previously held ownership of
            its media arm the Citizen.lk website. The company provided 100 per
            cent of the funding without any conditions to commence the
            operations of Citizen Fact Check and maintain its operations for a
            period of two year after being convinced of the merits of the new
            service. The funding support extended to all costs relating to
            hiring and salaries of staff. Initial Citizen Fact Check operations
            were also supported by staff from Citizen.lk.
          </p>

          <p>
            Digital Content is registered as a private limited company (Company
            Registration No. PV 114736) under the Sri Lanka Companies Act No 7
            of 2007. Its directors are Arjun Jeger and Chamila Prasad
            Karunarathne . Its accounts are audited by an external auditor
            annually and any public who is interested can be access via
            registered of companies.
          </p>
          <p>
            <b>June 2021</b> – Citizen Media Network was formed and was
            registered as a private limited company (Company Registration No. PV
            00240614) under the Sri Lanka Companies Act No 7 of 2007 bringing in
            all media related units under the ownership Digital Content (Pvt)
            Ltd. under the banner of Citizen Media Network and making it a
            distinct company from Digital Content (Pvt) Ltd. This was done to
            ensure better transparency and editorial independence. All editorial
            decisions are made by the Editor in Chief. Any attempt to exert
            undue influence is not tolerated. One additional staff member has
            joined the team since.
          </p>
          <p>
            <b>Going Forward</b> – While initial funding provided by Digital
            Content (Pvt) Ltd is set to end in March 2023, Citizen Media Network
            expects to adopt a model of funding where 50 per cent of the
            necessary funds will be borne by Digital Content (Pvt) Ltd. while
            the remainder will be secured through various grant opportunities,
            third party fact checking service , public funding and advertising.
          </p>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default connect(null, mapDispatchToProps)(AboutUs);
