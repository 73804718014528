import React, { Fragment, PureComponent } from "react";
import "./index.css";
import GooglePlay from "../../img/GooglePlay.jpg";
import AppleStore from "../../img/AppleStore.jpg";
import FooterLogo from "../../img/logo.png";

import facebook02 from "../../img/facebook02.svg";
import instagram from "../../img/instagram.svg";
import twitter from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
export default class Footer extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleCategory = (name, id) => {
    window.top.location.href =
      "https://si.citizen.lk/category/" + id + `/` + name;
    // this.props.history.push(`/category/` + id + `/` + name);
  };
  render() {
    return (
      <Fragment>
        <div className="footer-bg-01">
          <div className="row d-flex justify-content-center">
            <div className="footer-container-01">
              <p className="footer-title-01 text-center">
                Download Citizen News App and Get Notified
              </p>
              <div className="row d-flex justify-content-center">
                <a
                  href="https://play.google.com/store/apps/details?id=lk.bhasha.citizen&hl=en&gl=US"
                  target="_blank"
                >
                  <img
                    className="store-img-01"
                    src={GooglePlay}
                    alt="GooglePlay"
                  />
                </a>
                <img
                  className="store-img-02"
                  src={AppleStore}
                  alt="AppleStore"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bg-02">
          <div className="row">
            <div className="col-12 col-sm-3">
              <img className="footer-logo" src={FooterLogo} alt="logo" />
              <p className="footer-text-01 mt-4">
                Digital Content Private Limited <br /> No 96/8, Kottanuwatta
                Road, Nugegoda, Sri Lanka
              </p>

              <p className="footer-text-02 pt-4">
                <i
                  className="fas fa-envelope pr-3 text-white"
                  aria-hidden="true"
                ></i>
                news@citizen.lk
              </p>
              <p className="footer-text-02">
                <i
                  className="fa fa-phone pr-3 text-white"
                  aria-hidden="true"
                ></i>
                Tel: 011 281 0532
              </p>
              <p className="footer-text-02">
                <i
                  className="fa fa-phone pr-3 text-white"
                  aria-hidden="true"
                ></i>
                Tel: 076 441 2285
              </p>
              <p className="footer-title-02">Social Links</p>
              <div className="row">
                <a
                  className="my-auto"
                  href="https://www.facebook.com/citizenlk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={facebook02}
                    alt="facebook02"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.instagram.com/citizen.lk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={instagram}
                    alt="instagram"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://twitter.com/citizen_newslk"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={youtube}
                    alt="youtube"
                  />
                </a>
              </div>
            </div>

            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Categories</p>
              <div className="row footer-text-01 mt-4">
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("එතෙර", 10)}
                    style={{ cursor: "pointer" }}
                  >
                    එතෙර
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("කෙළි බිම", 11)}
                    style={{ cursor: "pointer" }}
                  >
                    කෙළි බිම
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("දේශපාලන", 12)}
                    style={{ cursor: "pointer" }}
                  >
                    දේශපාලන
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("ආර්ථික", 2)}
                    style={{ cursor: "pointer" }}
                  >
                    ආර්ථික
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("මෙන්න ඇත්ත", 5)}
                    style={{ cursor: "pointer" }}
                  >
                    මෙන්න ඇත්ත
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Citizen News</p>
              <div className="row footer-text-01 mt-4">
                <div className="col-12">
                  <a
                    href="https://fc.citizen.lk"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p> FACT CHECK</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="https://fc.citizen.lk/citizen/focus"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>CITIZEN FOCUS</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="https://citizen.lk/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>CITIZEN පුවත්</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Information</p>
              <div className="row footer-text-01 mt-4">
                <div className="col-12">
                  <a
                    href="/about-us"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p> About Us</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/privacy-policy"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Privacy Policy</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/our-team"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Our Team</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/contact-us"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Contact Us</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/termsandconditions"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Terms and Conditions</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Legal</p>
              <div className="row footer-text-01 mt-4">
                <div className="col-12">
                  <a
                    href="/methodology"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Methodology</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/corrections-and-complaints-policy"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Corrections And Complaints Policy</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/non-partisanship-policy"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Non-Partisanship Policy</p>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href="/trustees-patrons"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Trustees & Patrons</p>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-3 pt-4">
              <p className="footer-title-02">Social Links</p>
              <div className="row">
                <a
                  className="my-auto"
                  href="https://www.facebook.com/citizenlk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={facebook02}
                    alt="facebook02"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.instagram.com/citizen.lk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={instagram}
                    alt="instagram"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://twitter.com/citizen_newslk"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={youtube}
                    alt="youtube"
                  />
                </a>
              </div>
            </div> */}
          </div>

          <hr
            style={{
              border: "1px solid rgba(255, 255, 255, 1)",
              backgroundColor: "white",
            }}
          />

          <div className="row mx-0">
            <div className="col-md-6 col-sm-12 d-flex justify-content-md-start justify-content-center justify-content-sm-center p-0 mx-0">
              <small className="text-white">
                © Citizen.lk All rights reserved.
              </small>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-center justify-content-md-end justify-content-sm-center p-0 mx-0">
              <p className="footer-text-3 text-center text-white mb-0">
                Made with&nbsp;
                <i className="fa fa-heart text-danger" aria-hidden="true"></i>
                &nbsp;by&nbsp;
                <a
                  href="https://codebrixtech.com/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  CodeBrix (Pvt) Ltd
                </a>
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
