export const ACTION_AUTH_LOGIN = "ACTION_AUTH_LOGIN";
export const ACTION_REFRESH_TOKEN = "ACTION_REFRESH_TOKEN";

export const ACTION_GET_ALL_FACT_CHECK = "ACTION_GET_ALL_FACT_CHECK";
export const ACTION_GET_FACT_CHECK_BY_ID = "ACTION_GET_FACT_CHECK_BY_ID";

export const ACTION_GET_ALL_FACT_CHECK_ARTICLE =
  "ACTION_GET_ALL_FACT_CHECK_ARTICLE";
export const ACTION_GET_FACT_CHECK_ARTICLE_BY_ID =
  "ACTION_GET_FACT_CHECK_ARTICLE_BY_ID";

export const ACTION_GET_ALL_POSTS = "ACTION_GET_ALL_POSTS";
export const ACTION_GET_TRENDING_POSTS = "ACTION_GET_TRENDING_POSTS";
export const ACTION_GET_POST_BY_ID = "ACTION_GET_POST_BY_ID";
export const ACTION_GET_POST_BY_SEARCH = "ACTION_GET_POST_BY_SEARCH";
export const ACTION_GET_POST_BY_TAG = "ACTION_GET_POST_BY_TAG";

export const ACTION_SUBSCRIBED = "ACTION_SUBSCRIBED";

export const ACTION_GET_POST_SLUG = "ACTION_GET_POST_SLUG";
export const ACTION_GET_FACT_SLUG = "ACTION_GET_FACT_SLUG";

export const ACTION_GET_SYSTEM_THEME = "ACTION_GET_SYSTEM_THEME";

export const ACTION_GET_SYSTEM_ACTIVE_TAB = "ACTION_GET_SYSTEM_ACTIVE_TAB";
export const ACTION_ADD_VIEW_COUNT = "ACTION_ADD_VIEW_COUNT";
export const ACTION_GET_ADS = "ACTION_GET_ADS";
export const ACTION_ADD_COUNT = "ACTION_ADD_COUNT";
export const ACTION_GET_VIDEOS = "ACTION_GET_VIDEOS";
export const ACTION_GET_LIVE_NEWS = "ACTION_GET_LIVE_NEWS";
export const ACTION_GET_VIDEO_BY_CATEGORY = "ACTION_GET_VIDEO_BY_CATEGORY";
