import moment from "moment";
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getFactBySlug,
  addCount,
  getAllAds,
} from "../../store/actions/app-state-action";
import NoImg from "../../img/no-img.jpg";
import FactCheck from "./FactCheck";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import "./index.css";
import misleadingLogo from "../../img/processing.png";
import notVerfiyLogo from "../../img/False.png";
import VerfiyLogo from "../../img/truth.png";
import MDEditor from "@uiw/react-md-editor";

class FactCheckArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      article: {},
      adList: [],
      factCheck: {
        Title: "",
        Author: "",
        DateCreated: "",
        Description: "",
        Status: "",
        Thumbnail: "",
        Verification: "",
      },
      slug: this.props.match.params.slug,
      success: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllAds();
    document.getElementById("collapseExample").classList.remove("show");
    this.props.getPostBySlug(this.props.match.params.slug);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      window.scrollTo(0, 0);
      this.setState({
        ...this.state,
        slug: this.props.match.params.slug,
        success: false,
      });

      this.props.getPostBySlug(this.props.match.params.slug);
    }

    if (prevProps.factCheckState !== this.props.factCheckState) {
      if (this.props.factCheckState.success) {
        this.setState({
          ...this.state,
          factCheck: this.props.factCheckState.data[0]
            ? this.props.factCheckState.data[0]
            : { ...this.state.factCheck },
          success: true,
        });
      } else {
        this.setState({
          ...this.state,
          success: false,
        });
      }
    }
  }
  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.location.assign(url);
  };
  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
              {this.state.success ? (
                <Fragment>
                  {this.state.factCheck.Title ? (
                    <Fragment>
                      <p className="fc-article-title">
                        {this.state.factCheck.Title}
                      </p>
                      <p className="verifed-text">
                        <b>Verified through</b> Citizen Fact Check
                      </p>
                      <p className="verifed-text">
                        <b>Verification by </b>
                        {this.state.factCheck.ModifiedAuthor
                          ? this.state.factCheck.ModifiedAuthor
                          : this.state.factCheck.Author}{" "}
                        <b>on</b>
                        &nbsp;
                        {moment(this.state.factCheck.DateCreated).format(
                          "DD MMMM YYYY, hh:mm:ss A"
                        )}
                      </p>
                      <MDEditor.Markdown
                        source={this.state.factCheck.Description}
                        style={{
                          whiteSpace: "pre-wrap",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      />
                      {/* <p
                        className="mt-4 text-wrap-article"
                        dangerouslySetInnerHTML={{
                          __html: this.state.factCheck.Description,
                        }}
                      ></p> */}
                    </Fragment>
                  ) : (
                    <h3>Article Content Missing!</h3>
                  )}
                </Fragment>
              ) : null}
              {this.state.factCheck.Title ? (
                <Fragment>
                  <p className="mt-3 mb-3">
                    <b>Rating</b>
                  </p>
                  {this.state.factCheck.Status === "1" ? (
                    <img
                      className="rating-logo"
                      src={VerfiyLogo}
                      alt="ratings logo"
                    />
                  ) : this.state.factCheck.Status === "2" ? (
                    <div
                      className="col-12  p-3 mb-2 bg-warning text-dark"
                      style={{ borderRadius: "10px" }}
                    >
                      <label className="">Pending</label>
                    </div>
                  ) : this.state.factCheck.Status === "3" ? (
                    <img
                      className="rating-logo"
                      src={misleadingLogo}
                      alt="ratings logo"
                    />
                  ) : (
                    ""
                  )}

                  <p>
                    <b>Origin of Story</b>
                  </p>
                </Fragment>
              ) : null}
              {this.state.success ? (
                <Fragment>
                  <MDEditor.Markdown
                    source={this.state.factCheck.Origin}
                    style={{
                      whiteSpace: "pre-wrap",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                  {/* <p
                    className="mt-4 text-wrap-article"
                    dangerouslySetInnerHTML={{
                      __html: this.state.factCheck.Origin,
                    }}
                  ></p> */}
                  {/* {this.state.factCheck.Title ? (
                    <img
                      className="article-img mb-4"
                      src={
                        API_STORAGE_BASE_URL + this.state.factCheck.Thumbnail
                      }
                      alt="article image"
                      onError={this.addDefaultSrc}
                    />
                  ) : null} */}
                </Fragment>
              ) : null}
              {this.state.factCheck.Title ? (
                <p>
                  <b>Verification</b>
                </p>
              ) : null}
              {this.state.success ? (
                <Fragment>
                  {this.state.factCheck.Title ? (
                    <MDEditor.Markdown
                      source={this.state.factCheck.Verification}
                      style={{
                        whiteSpace: "pre-wrap",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    />
                  ) : // <div
                  //   className="focus-article-text-span text-wrap-article"
                  //   dangerouslySetInnerHTML={{
                  //     __html: this.state.factCheck.Verification,
                  //   }}
                  // ></div>
                  null}
                </Fragment>
              ) : null}
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 h-100">
              <FactCheck />
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 06") {
                    return (
                      <Fragment key={index}>
                        <div className="card article-ad-right  mt-3">
                          <img
                            src={
                              API_STORAGE_BASE_URL + item.Thumbnail.toString()
                            }
                            alt="advertisement"
                            onClick={(e) =>
                              this.handleCount(item.Id, item.Count, item.Url)
                            }
                            onError={this.addDefaultSrc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Fragment>
                    );
                  }
                })}
            </div>
          </div>
          {this.state.factCheck.Title && (
            <div className="row">
              <div className="col-12">
                <p className="mt-3">Share this on</p>
                <div className="row ml-1">
                  <div className="social-media-link">
                    <a
                      target="_blank"
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=https://fc.citizen.lk/article.php?slug=" +
                        this.state.slug
                      }
                    >
                      <i
                        class="fab fa-facebook"
                        style={{ color: "rgb(66, 103 ,178)", fontSize: 20 }}
                      ></i>
                    </a>
                  </div>
                  <div className="social-media-link">
                    <a
                      target="_blank"
                      href={
                        "http://twitter.com/share?text=" +
                        this.state.slug +
                        "&url=https://fc.citizen.lk/article.php?slug=" +
                        this.state.slug
                      }
                    >
                      <i
                        class="fab fa-twitter"
                        style={{ color: "#00acee", fontSize: 20 }}
                      ></i>
                    </a>
                  </div>
                  <div className="social-media-link">
                    <a
                      target="_blank"
                      href={
                        "https://api.whatsapp.com/send?text=" +
                        this.state.slug +
                        "...https://fc.citizen.lk/article.php?slug=" +
                        this.state.slug
                      }
                      data-action="share/whatsapp/share"
                    >
                      <i
                        class="fab fa-whatsapp"
                        style={{ color: "rgb(59, 121, 55)", fontSize: 20 }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {this.state.adList &&
                  this.state.adList.length !== 0 &&
                  this.state.adList.map((item, index) => {
                    if (item.Section === "Section 07") {
                      return (
                        <div className="ad mt-4 mb-4" key={index}>
                          <img
                            src={
                              API_STORAGE_BASE_URL + item.Thumbnail.toString()
                            }
                            alt="advertisement"
                            onClick={(e) =>
                              this.handleCount(item.Id, item.Count, item.Url)
                            }
                            onError={this.addDefaultSrc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
              {/* <div className="col-12 col-sm-8 d-flex justify-content-center mt-4">
                <img
                  src="https://media.giphy.com/media/4TIJCOWXNxHxCsKbEV/giphy.gif"
                  alt="gif"
                  width="250"
                  height="250"
                />
              </div> */}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    factCheckState: state.appStore.factCheck,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostBySlug: (slug) => dispatch(getFactBySlug(slug)),
  addCount: (data) => dispatch(addCount(data)),
  getAllAds: () => dispatch(getAllAds()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactCheckArticle)
);
