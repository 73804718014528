import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_FACT_CHECK,
  ACTION_GET_FACT_CHECK_BY_ID,
  ACTION_GET_ALL_FACT_CHECK_ARTICLE,
  ACTION_GET_FACT_CHECK_ARTICLE_BY_ID,
  ACTION_GET_TRENDING_POSTS,
  ACTION_GET_ALL_POSTS,
  ACTION_GET_POST_BY_ID,
  ACTION_GET_POST_BY_SEARCH,
  ACTION_GET_POST_BY_TAG,
  ACTION_SUBSCRIBED,
  ACTION_GET_POST_SLUG,
  ACTION_GET_FACT_SLUG,
  ACTION_GET_SYSTEM_THEME,
  ACTION_GET_SYSTEM_ACTIVE_TAB,
  ACTION_ADD_VIEW_COUNT,
  ACTION_GET_ADS,
  ACTION_ADD_COUNT,
  ACTION_GET_VIDEOS,
  ACTION_GET_LIVE_NEWS,
  ACTION_GET_VIDEO_BY_CATEGORY,
} from "../../constants/actions";
const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAll = (lan, from, to) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_ALL_FACT_CHECK, data: {} };
  dispatch(actionData);
  await AppServices.getAll(lan, from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_FACT_CHECK_BY_ID,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getById(id)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllArticle = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_FACT_CHECK_ARTICLE,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getAllArticle(from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getArticleById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_FACT_CHECK_ARTICLE_BY_ID,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getArticleById(id)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllPosts = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_POSTS,
    data: {},
    status: false,
  };
  // dispatch(actionData);
  await AppServices.getAllPosts(from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getTrendingPosts = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_TRENDING_POSTS,
    data: {},
  };
  await AppServices.getTrendingPosts(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostById = (name) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_POST_BY_ID, data: {} };
  dispatch(actionData);
  await AppServices.getPostById(name)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostBySearch = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_BY_SEARCH,
    data: {},
  };
  await AppServices.getPostBySearch(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostByTag = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_BY_TAG,
    data: {},
  };
  await AppServices.getPostByTag(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const subscribe = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_SUBSCRIBED,
    data: {},
  };
  await AppServices.subscribe(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostBySlug = (slug) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_SLUG,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getPostBySlug(slug)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getFactBySlug = (slug) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_FACT_SLUG,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getFactBySlug(slug)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getSystemTheme = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_SYSTEM_THEME,
    data: {},
  };

  try {
    var density = await localStorage.getItem("systemTheme");
  } catch (error) {
    //console.log(error.message);
  }

  if (density !== null) {
    // console.log(density);
    actionData = {
      ...actionData,
      isLoading: false,
      isError: false,
      message: "",
      success: true,
      data: density,
    };
  } else {
    actionData = {
      ...actionData,
      data: [],
      isLoading: false,
      isError: true,
      message: "",
      success: false,
    };
  }

  dispatch(actionData);
};

export const getSystemActiveTab = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_SYSTEM_ACTIVE_TAB,
    data: {},
  };

  try {
    var systemActiveTab = await localStorage.getItem("SAT");
  } catch (error) {
    //console.log(error.message);
  }

  if (systemActiveTab !== null) {
    // console.log(density);
    actionData = {
      ...actionData,
      isLoading: false,
      isError: false,
      message: "",
      success: true,
      data: systemActiveTab,
    };
  } else {
    actionData = {
      ...actionData,
      data: [],
      isLoading: false,
      isError: true,
      message: "",
      success: false,
    };
  }

  dispatch(actionData);
};

export const addViewCount = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_ADD_VIEW_COUNT, data: {} };
  dispatch(actionData);
  await AppServices.addViewCount(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
export const getAllAds = () => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_ADS, data: {} };
  dispatch(actionData);
  await AppServices.getAllAds()
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
export const addCount = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_ADD_COUNT, data: {} };
  dispatch(actionData);
  await AppServices.addCount(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
export const getAllVideo = (from, to) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_VIDEOS, data: {} };
  dispatch(actionData);
  await AppServices.getAllVideo(from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getLiveNews = () => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_LIVE_NEWS, data: {} };
  dispatch(actionData);
  await AppServices.getLiveNews()
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getVideoByCategory = (data, id, from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_VIDEO_BY_CATEGORY,
    data: [],
    key: id,
    status: false,
  };
  dispatch(actionData);
  await AppServices.getVideoByCategory(data, from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        key: id,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
