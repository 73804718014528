import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import NoImg from "../../img/no-img.jpg";
import {
  getPostBySearch,
  getSystemTheme,
  getSystemActiveTab,
} from "../../store/actions/app-state-action";
import { API_STORAGE_BASE_URL } from "../../constants/api";

class SearchList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      recent: [],
      tagPosts: [],
      tagSuccess: false,
      keyword: this.props.match.params.name,
      searchKeyword: this.props.match.params.name,
      errorMsg: "",
      isError: false,
      isDarkMode: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("collapseExample").classList.remove("show");
    this.props.getSystemTheme();
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    this.props.getPostBySearch(this.props.match.params.name);
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        recent: this.props.postsState.data,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.setState({
        ...this.state,
        tagPosts: [],
        keyword: this.props.match.params.name,
        tagSuccess: false,
      });
      this.props.getPostBySearch(this.props.match.params.name);
    }

    if (this.props.searchState !== prevProps.searchState) {
      if (this.props.searchState.success) {
        this.setState({
          ...this.state,
          tagPosts: this.props.searchState.data.filter(
            (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i
          ),
          tagSuccess: true,
          success: true,
        });
      }
    }

    if (this.props.postsState !== prevProps.postsState) {
      if (this.props.postsState.success) {
        this.setState({
          ...this.state,
          recent: this.props.postsState,
          success: true,
        });
      }
    }
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
  }

  handleRedirect = (id) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + id);
    window.top.location.href = `/citizen/focus/` + id;
  };

  handleSearch = () => {
    if (this.state.keyword.length > 0) {
      this.setState({
        ...this.state,
        searchKeyword: this.state.keyword,
        tagPosts: [],
        success: false,
      });
      this.props.getPostBySearch(this.state.keyword);
      // .then(() => {
      //   if (this.props.searchState.success) {
      //     this.setState({
      //       ...this.state,
      //       tagPosts: this.props.searchState.data,
      //       tagSuccess: true,
      //       success: true,
      //     });
      //   }
      // });
      // this.props.history.push(`/citizen/search/` + this.state.keyword);
      window.top.location.href = `/citizen/search/` + this.state.keyword;
    } else {
      this.showError("Please enter your search keywords.");
    }
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        showSearch: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            errorMsg: "",
            isError: false,
          });
        }, 3000);
      }
    );
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-sm-3" id="search-post-sm">
              <p>
                <b>Search Posts</b>
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search keywords"
                  aria-label="Search keywords"
                  aria-describedby="basic-addon2"
                  style={{ borderRight: "none" }}
                  id="keyword"
                  onChange={this.handleChange}
                  value={this.state.keyword}
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white"
                    id="basic-addon2"
                    onClick={(e) => this.handleSearch()}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              {this.state.isError && (
                <div className="alert alert-danger" role="alert">
                  {this.state.errorMsg}
                </div>
              )}
              {this.state.recent &&
                this.state.recent.length !== 0 &&
                this.state.recent.map((item, index) => {
                  return (
                    <div
                      className="row mb-3"
                      key={index}
                      onClick={(e) => this.handleRedirect(item.Slug)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="col-12">
                        {item.Title}
                        <hr
                          className={
                            this.state.isDarkMode ? "dark-hr-post" : "hr-post "
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-12 col-sm-9">
              <p>
                SEARCH RESULTS FOR <label>{this.state.searchKeyword}</label>{" "}
              </p>

              {this.state.tagPosts.length !== 0 ? (
                <p className="tag-subtext">
                  {this.state.tagPosts.length}
                  {this.state.tagPosts.length === 1 ? " post" : " posts"}
                </p>
              ) : null}
              {this.state.success ? (
                this.state.searchKeyword && this.state.tagPosts.length !== 0 ? (
                  this.state.tagPosts.map((item, index) => {
                    return (
                      <div className="post-list-container mb-4" key={index}>
                        <div
                          className="row mb-4"
                          onClick={(e) => this.handleRedirect(item.Slug)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="col-6">
                            <img
                              className="post-list-img"
                              src={
                                item.Thumbnail.length !== 0 && item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              onError={this.addDefaultSrc}
                            />
                          </div>
                          <div className="col-6">
                            <p>CITIZEN FOCUS</p>
                            <p className="post-title">{item.Title}</p>
                            <p>
                              {moment(item.DateCreated).format("DD MMMM YYYY")}
                            </p>
                            <div
                              className="focus-article-text-span"
                              dangerouslySetInnerHTML={{
                                __html: item.Article[0],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h3>Search results not found!</h3>
                )
              ) : (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-3" id="search-post-md">
              <p>
                <b>Search Posts</b>
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search keywords"
                  aria-label="Search keywords"
                  aria-describedby="basic-addon2"
                  style={{ borderRight: "none" }}
                  id="keyword"
                  onChange={this.handleChange}
                  value={this.state.keyword}
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white"
                    id="basic-addon2"
                    onClick={(e) => this.handleSearch()}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              {this.state.isError && (
                <div className="alert alert-danger" role="alert">
                  {this.state.errorMsg}
                </div>
              )}
              {this.state.recent &&
                this.state.recent.length !== 0 &&
                this.state.recent.map((item, index) => {
                  return (
                    <div
                      className="row mb-3"
                      key={index}
                      onClick={(e) => this.handleRedirect(item.Slug)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="col-12">
                        {item.Title}
                        <hr
                          className={
                            this.state.isDarkMode ? "dark-hr-post" : "hr-post "
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchState: state.appStore.search,
    postsState: state.appStore.posts,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostBySearch: (id) => dispatch(getPostBySearch(id)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchList)
);
