import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../Home/index";
import FactCheckArticle from "../Article/index";
import FocusArticle from "../FocusArticle/index";
import PostByTag from "../Tags/index";
import SearchList from "../Search/index";
import CitizenFocus from "../FocusHome/index";
import ViewMoreArticles from "../MoreArticles/index";
import NewsLetter from "../NewsLetter";
import TermAndCondition from "../Term&Condition";
import AboutUs from "../Common/AboutUs";
import ContactUs from "../Common/ContactUs";
import CorrectionsAndComplaintsPolicy from "../Common/CorrectionsAndComplaintsPolicy";
import Methodology from "../Common/Methodology";
import NonPartisanshipPolicy from "../Common/Non-PartisanshipPolicy";
import OurTeam from "../Common/OurTeam";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import TrusteesPatrons from "../Common/Trustees&Patrons";
import PageNotFound from "../PageNotFound";

class Routes extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/article/:slug" component={FactCheckArticle} />
        <Route exact path="/citizen/focus/:name" component={FocusArticle} />
        <Route exact path="/citizen/tags/:name" component={PostByTag} />
        <Route exact path="/citizen/search/:name" component={SearchList} />
        <Route exact path="/citizen/focus" component={CitizenFocus} />
        <Route exact path="/citizen/article/all" component={ViewMoreArticles} />
        <Route exact path="/newsletter" component={NewsLetter} />
        <Route exact path="/termsandconditions" component={TermAndCondition} />

        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route
          exact
          path="/corrections-and-complaints-policy"
          component={CorrectionsAndComplaintsPolicy}
        />
        <Route exact path="/methodology" component={Methodology} />
        <Route
          exact
          path="/non-partisanship-policy"
          component={NonPartisanshipPolicy}
        />
        <Route exact path="/our-team" component={OurTeam} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/trustees-patrons" component={TrusteesPatrons} />
        <Route path="*" exact={true} component={PageNotFound} />
      </Switch>
    );
  }
}

export default Routes;
