import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getPostByTag,
  getSystemTheme,
  getSystemActiveTab,
} from "../../store/actions/app-state-action";
import moment from "moment";
import NoImg from "../../img/no-img.jpg";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import MDEditor from "@uiw/react-md-editor";

class PostByTag extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      recent: [],
      tagPosts: [],
      tagSuccess: false,
      keyword: "",
      errorMsg: "",
      isError: false,
      isDarkMode: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    this.props.getSystemTheme();
    this.props.getPostByTag(this.props.match.params.name);
    if (this.props.postsState.success) {
      this.setState({
        ...this.state,
        recent: this.props.postsState.data,
        // success: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.tagState !== prevProps.tagState) {
      if (this.props.tagState.success) {
        this.setState({
          ...this.state,
          tagPosts: this.props.tagState.data,
          tagSuccess: true,
          success: true,
        });
      }
    }
    if (this.props.postsState !== prevProps.postsState) {
      if (this.props.postsState.success) {
        this.setState({
          ...this.state,
          recent: this.props.postsState.data,
          success: true,
        });
      }
    }
    if (this.props.systemThemeState !== prevProps.systemThemeState) {
      this.setState({
        ...this.state,
        isDarkMode:
          this.props.systemThemeState.data === "darkTheme" ? true : false,
      });
    }
  }

  handleRedirect = (id) => {
    localStorage.setItem("SAT", 1);
    this.props.getSystemActiveTab();
    // this.props.history.push(`/citizen/focus/` + id);
    window.top.location.href = `/citizen/focus/` + id;
  };

  handleSearch = () => {
    if (this.state.keyword.length !== 0) {
      // this.props.history.push(`/citizen/search/` + this.state.keyword);
      window.top.location.href = `/citizen/search/` + this.state.keyword;
    } else {
      this.showError("Please enter your search keywords.");
    }
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        showSearch: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3000);
      }
    );
  };
  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  getContent(postContets) {
    var textArray = [];
    var text = "";
    var contentArr = postContets ? postContets.split("![]") : [];

    if (contentArr.length !== 0) {
      contentArr.forEach((element) => {
        if (!element.includes("(https://api.citizen.lk/public")) {
          textArray.push(element.replace(/(&nbsp;|<([^>]+)>\n)/gi, ""));
        }
      });

      text = textArray.length !== 0 ? textArray.join(".") : "";
    }

    return text;
  }
  render() {
    return (
      <Fragment>
        <div className="fact-check-wrapper">
          <div className="row">
            <div className="col-12 col-sm-9">
              <p>BROWSING TAG</p>
              <p className="tag-name">{this.props.match.params.name}</p>
              {this.state.tagPosts.length !== 0 ? (
                <p className="tag-subtext">
                  {this.state.tagPosts.length}{" "}
                  {this.state.tagPosts.length <= 1 ? "post" : "posts"}
                </p>
              ) : null}
              {this.state.success ? (
                this.state.tagPosts.length !== 0 ? (
                  this.state.tagPosts.map((item, index) => {
                    return (
                      <div
                        className="post-list-container mb-4"
                        data-aos="fade-up"
                        key={index}
                      >
                        <div
                          className="row mb-4"
                          onClick={(e) => this.handleRedirect(item.Slug)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="col-6">
                            <img
                              className="post-list-img"
                              src={
                                item.Thumbnail.length !== 0 && item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              onError={this.addDefaultSrc}
                              alt="base"
                            />
                          </div>
                          <div className="col-6">
                            <p>CITIZEN FOCUS</p>
                            <p className="post-title">{item.Title}</p>
                            <p>
                              {moment(item.DateCreated).format("DD MMMM YYYY")}
                            </p>
                            {/* <div
                              className="focus-article-text-span"
                              dangerouslySetInnerHTML={{
                                __html: item.Article[0],
                              }}
                            /> */}
                            <MDEditor.Markdown
                              source={
                                item.Article[0].length > 500
                                  ? `${this.getContent(
                                      item.Article[0]
                                    ).substring(0, 500)}...`
                                  : this.getContent(item.Article[0])
                              }
                              style={{
                                whiteSpace: "pre-wrap",
                                backgroundColor: "white",
                                color: "black",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h3>No posts were found under this tag!</h3>
                )
              ) : (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-3">
              <p>
                <b>Search Posts</b>
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search keywords"
                  aria-label="Search keywords"
                  aria-describedby="basic-addon2"
                  style={{ borderRight: "none" }}
                  id="keyword"
                  onChange={this.handleChange}
                  value={this.state.keyword}
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-white"
                    id="basic-addon2"
                    onClick={(e) => this.handleSearch()}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              {this.state.isError && (
                <div className="alert alert-danger" role="alert">
                  {this.state.errorMsg}
                </div>
              )}
              {this.state.recent &&
                this.state.recent.length !== 0 &&
                this.state.recent
                  .filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i)
                  .map((item, index) => {
                    return (
                      <div
                        className="row mb-3"
                        key={index}
                        onClick={(e) => this.handleRedirect(item.Slug)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="col-12">
                          {item.Title}
                          <hr
                            className={
                              this.state.isDarkMode
                                ? "dark-hr-post"
                                : "hr-post "
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagState: state.appStore.tags,
    postsState: state.appStore.posts,
    systemThemeState: state.appStore.systemTheme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostByTag: (id) => dispatch(getPostByTag(id)),
  getSystemTheme: () => dispatch(getSystemTheme()),
  getSystemActiveTab: () => dispatch(getSystemActiveTab()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostByTag)
);
